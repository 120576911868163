import React  from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { ListUserTypes, AddUserTypes } from "./index"

export const UserTypes = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("UserTypes");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/user-types"
        to="/user-types/list"
      />
      <ContentRoute from="/user-types/add" component={AddUserTypes} />
      <ContentRoute from="/user-types/list" component={ListUserTypes} />
      <ContentRoute from="/user-types/:id/edit" component={AddUserTypes} />
    </Switch>
  );
};
