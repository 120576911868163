
const actionTypes = {
    set: "[Used user-types set] Action"
};

const initialToastState = {
    used: []
};

export const reducer = (state = initialToastState, action) => {
    switch (action.type) {
        case actionTypes.set: {
            state.used = [...action.payload]
            return { ...state };
        }
        default:
            return state;
    }
}


export const actions = {
    setStore: message => ({ type: actionTypes.set, payload: [ ...message ] }),
};
