
export const actionTypes = {
    open: "[Alert open] Action",
    close: "[Alert close] Action"
};

const initialAuthState = {
    open: false,
    message: null,
    type: null, 
    cb: null,
    title: null
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.open: {
            let newState = {...state}
            newState.title = null
            return { newState, ...action.payload };
        }
        case actionTypes.close: {
            let newState = { ...state }
            newState.title = null
            return { newState, ...action.payload };
        }
        default:
            return state;
    }
}


export const actions = {
    showAlert: message => ({ type: actionTypes.open, payload: { ...message } }),
    closeAlert: message => ({ type: actionTypes.close, payload: { ...message } })
};
