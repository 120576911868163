/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { MenuItems } from "./AsideMenuItems"

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const getMenu = (parentPath, menuItems) => {
    let menus = menuItems.map((item, index) => {
      let path = parentPath + item.path
      return (
        <li className={`menu-item ${getMenuItemActive(path, false)}`}
          aria-haspopup="true"
          key={`menu-items-${index + 1}`}>
          <NavLink className="menu-link" to={path}>
            {
              item.iconSvg ? (
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(item.iconSvg)} />
                </span>
              ) :
                (
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                )
            }
            <span className="menu-text">{item.label}</span>
            {item.hasSubMenu && <i className="menu-arrow" />}
          </NavLink>
          {item.hasSubMenu ? (
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">{item.label}</span>
                    </span>
                  </li>
                  {getMenu(path + '/', item.subMenuItems)}
                </ul>
              </ul>
            </div>
          ) : null}
        </li>
      )
    })
    return menus
  }
  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {getMenu("/", [...MenuItems])}
    </ul>
  )
  // return (
  //   <>
  //     <ul className={`menu-nav ${layoutProps.ulClasses}`}>
  //       <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
  //         aria-haspopup="true">
  //         <NavLink className="menu-link" to="/dashboard">
  //           <span className="svg-icon menu-icon">
  //             <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
  //           </span>
  //           <span className="menu-text">Dashboard</span>
  //         </NavLink>
  //       </li>
  //       <li className={`menu-item ${getMenuItemActive("/stores", false)}`}
  //         aria-haspopup="true">
  //         <NavLink className="menu-link" to="/stores">
  //           <span className="svg-icon menu-icon">
  //             <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
  //           </span>
  //           <span className="menu-text">Stores</span>
  //           <i className="menu-arrow" />
  //         </NavLink>
  //         <div className="menu-submenu ">
  //           <ul className="menu-subnav">
  //             <ul className="menu-subnav">
  //               <li
  //                 className="menu-item  menu-item-parent"
  //                 aria-haspopup="true"
  //               >
  //                 <span className="menu-link">
  //                   <span className="menu-text">Stores</span>
  //                 </span>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   "/stores/list"
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to="/stores/list">
  //                   <i className="menu-bullet menu-bullet-dot">
  //                     <span />
  //                   </i>
  //                   <span className="menu-text">List</span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   "/stores/add"
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to="/stores/add">
  //                   <i className="menu-bullet menu-bullet-dot">
  //                     <span />
  //                   </i>
  //                   <span className="menu-text">Add</span>
  //                 </NavLink>
  //               </li>
  //             </ul>
  //           </ul>
  //         </div>
  //       </li>
  //       {/* SUB HEAD */}
  //       {/* <li className="menu-section ">
  //           <h4 className="menu-text">Components</h4>
  //           <i className="menu-icon flaticon-more-v2"></i>
  //         </li> */}
  //     </ul>

  //     {/* end::Menu Nav */}
  //   </>
  // );
}
