import React, { useEffect, useRef } from 'react';

export function SearchBox({
    map,
    mapsapi,
    onPlacesChanged
}) {
    const inputRef = useRef()
    useEffect(() => {
        const { places } = mapsapi;
        if (places) {
            const searchBox = new places.SearchBox(inputRef.current);
            searchBox.addListener('places_changed', (ev) => {
                const location = searchBox.getPlaces()[0].geometry.location
                onPlacesChanged({ lat: location.lat(), lng: location.lng() })
            });
        }
    }, [map, mapsapi])
    return (
        <div className="row">
            <div className="col-lg-12 pl-6 pr-6">
                <div className="form-group row">
                    <input
                        className="form-control"
                        ref={inputRef}
                        placeholder="Search place..."
                        type="text"
                    />
                </div>
            </div>
        </div>
    )
}