import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { StoreEditForm } from "./StoreEditForm";
import { Store } from "../../utlis/API"
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import { useDispatch } from "react-redux";

const initialData = {
  name: '',
  description: '',
  userName: '',
  userEmail: '',
  userMobile: '',
  code: '',
  gstNumber: '',
  address: ''
}

// const initialData = {
//   description: "Test",
//   name: "New",
//   userEmail: "aj@yopmail.com",
//   userMobile: "1234567890",
//   userName: "asa"
// }
export const AddStores = (props) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(initialData);
  const [position, setPosition] = useState({});
  const [type, setType] = useState('add')
  const [storeId, setId] = useState(null)
  const [saving, setSaving] = useState(false)

  const backToStoresList = () => props.history.goBack()
  const saveStore = (values) => {
    let data = {
      "store": {
        "name": values.name,
        "desc": values.description,
        "lat": position.lat,
        "lon": position.lng,
        "store_code": values.code,
        "gst_no": values.gstNumber,
        "address": values.address
      },
      "user": {
        "name": values.userName,
        "mobile": values.userMobile,
        "email": values.userEmail
      }
    }
    if (type == 'edit') data['_method'] = "PUT"
    setSaving(true)
    Store.save(data, storeId).then(response => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Store successfully saved', type: 'success' }))
      if (type === 'add') resetbtnRef.current.click()
      else backToStoresList()
      setSaving(false)
    }).catch(err => {
      err = JSON.parse(err.message)
      let msg = loopErros(err.data.errors)
      if (msg == null) msg = 'Failed to save store'
      dispatch(toaster.actions.openToaster({ open: true, message: msg, type: 'error' }))
      setSaving(false)
    })
  }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }
  useEffect(() => {
    reInitializeComponent()
  }, [props.location]);
  const reInitializeComponent = async () => {
    try {
      let id = props.match.params.id
      if (id !== undefined) fetchData(id)
      else {
        let position = await getCurrentPosition()
        if (position.status) {
          console.log(position.data)
          setPosition({ ...position.data })
        }
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  const fetchData = (id) => {
    Store.getById(id).then((r) => {
      setId(id)
      const data = {
        name: r.storeDetail.name,
        description: r.storeDetail.desc,
        userName: r.storeAdminDetail.name,
        userEmail: r.storeAdminDetail.email,
        userMobile: r.storeAdminDetail.mobile,
        code: r.storeDetail.store_code,
        gstNumber: r.storeDetail.gst_no,
        address: r.storeDetail.address
      }
      const position = {
        lat: parseFloat(r.storeDetail.lat), lng: parseFloat(r.storeDetail.lon)
      }
      setType('edit')
      setData({ ...data })
      setPosition({ ...position })
    }).catch(e => {
      // dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to get Store', type: 'error' })
    })
  }
  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((data) => {
        resolve({
          status: true, data: { lat: data.coords.latitude, lng: data.coords.longitude }
        })
      }, (err) => {
        reject({ status: false })
      }, {})
    })
  }
  const handlemapClick = (res) => {
    let newPos = { lat: res.lat, lng: res.lng }
    setPosition({ ...newPos })
  }
  const loopErros = (data) => {
    let keys = Object.keys(data)
    if (keys.length > 0) {
      if (Array.isArray(data[keys[0]])) {
        let message = data[keys[0]][0]
        if (message !== undefined) return message
      }
    }
    else return null
  }
  return (
    <Card>
      <CardHeader title="Add stores">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            disabled={saving}
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveStoreClick}
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <StoreEditForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            save={saveStore}
            position={position}
            handlemapClick={handlemapClick}
          >
          </StoreEditForm>
        </div>

      </CardBody>
    </Card>
  )
};
