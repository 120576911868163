import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { ListDispatches } from "./ListDispatches"


export const Dispatches = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Dispatches");
    return (
        <Switch>
            <Redirect
                exact={true}
                from="/dispatches"
                to="/dispatches/list"
            />
            <ContentRoute from="/dispatches/list" component={ListDispatches} />
        </Switch>
    );
};
