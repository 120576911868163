import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Toaster } from "../../../_metronic/layout/components/extras/Toaster"
import { connect } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux";

import { CatEditForm } from "./CatEditForm";
import { Category } from "../../utlis/API"
import { injectIntl } from "react-intl";

export const _AddCats = (props) => {
  const [data, setData] = useState({ name: '', subactegoryId: '', image: null, description: '' });
  const [init,] = useState(true);
  const [feedback, setFeedback] = useState(false)
  const [feebackMsg, setFeebackMsg] = useState("")
  const [feebackType, setFeebackType] = useState("")
  const [type, setType] = useState('add')
  const [catId, setId] = useState(null)
  const [categories, setCategories] = useState([])
  const back = () => {
    props.history.push('/categories/list')
  }

  const saveCategory = (values) => {
    let formdata = new FormData()
    formdata.append('name', values.name)
    formdata.append('desc', values.description)
    if (values.subactegoryId !== "" && values.subactegoryId !== null) formdata.append('parent_id', values.subactegoryId)
    if (typeof values.image !== "string" && values.image !== null && values.image !== 0) formdata.append('img', values.image)
    if (type === 'edit') formdata.append('_method', 'PUT')
    Category.save(formdata, catId).then((res) => {
      props.openToaster({ open: true, message: 'Category successfully saved', type: 'success' })
      if (type === 'add') resetbtnRef.current.click()
      else back()
    }).catch(err => {
      props.openToaster({ open: true, message: 'Failed to save category', type: 'error' })
      console.log(err)
    })
  }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveCategoryClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }
  const handleClose2 = () => setFeedback(!feedback)
  useEffect(() => {
    Category.get().then((response) => {
      try {
        let categories = response.productCategories.map(cat => {
          return { value: cat.id, label: cat.name }
        })
        // console.log("Called again")
        setCategories([...categories])
      }
      catch (e) {
        console.log(e)
      }
    }).catch((err) => {

    })
    try {
      let id = props.match.params.id
      if (id !== undefined) fetchData(id)
    }
    catch (e) {
      console.log(e)
    }
  }, [init])
  const fetchData = (id) => {
    Category.getById(id).then((r) => {
      setId(id)
      r.productCategory.description = r.productCategory.desc ? r.productCategory.desc : ''
      if (r.productCategory.img == '') r.productCategory.img = 0
      r.productCategory.image = r.productCategory.img
      delete r.productCategory.desc
      delete r.productCategory.img
      setData(r.productCategory)
      setType('edit')
    }).catch(e => {
      setFeebackMsg('Failed to get category')
      setFeebackType('error')
      setFeedback(true)
    })
  }
  return (
    <Card>
      <CardHeader title="Add categories">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={back}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCategoryClick}
          >
            Save
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <CatEditForm
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveCategory={saveCategory}
            type={type}
            categories={categories}
          >
          </CatEditForm>
          {/* Move from here--- */}
          <Toaster
            open={feedback}
            handleClose={handleClose2}
            message={feebackMsg}
            type={feebackType}
          ></Toaster>
        </div>
      </CardBody>
    </Card>
  )
};
export const AddCats = injectIntl(connect(null, toaster.actions)(_AddCats))