import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Orders } from "../../utlis/API"
import { datePipe } from "../../utlis/CommonMethods"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
// import * as alert from "../../../redux/reducer-extras/alertRedux";
import { OrderDetails } from "./OrderDetails"
import {useReactToPrint} from 'react-to-print';
import Modal from "./Delivery/Modal"
import { format } from 'date-fns';
import CustomFilterComponent from "../../custom/CustomFilterComponent";
import PrintOrderDetail from "../../custom/PrintOrderDetail";

const _ListOrders = (props) => {

    // const dispatch = useDispatch()
    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "20", value: 20 }
        ],
        sizePerPage: 20,
        page: 1,
    };
    const columns = [
        {
            dataField: "slNo",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "id",
            text: "Order ID",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{'Order-' + cellContent}</span>)
            }
        },
        {
            dataField: "time_slot_id",
            text: "Time Slot",
            headerStyle: { width: '1px' },
            sort: true,
            sortCaret: sortCaret,
        },
  
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "mobile",
            text: "Mobile",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "amount_to_be_paid",
            text: "Paid amount",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "current_status",
            text: "Status",
            sort: true,
            formatter: (cellContent, row, rowIndex) => {
                // return (<span className="table-chip-order-status">{cellContent.name}</span>)
                return (<span className={ `badge
                 ${
                    cellContent.name === 'Delivered' ? 'badge-success' : 
                    cellContent.name === 'Order Placed' ? 'badge-info' :
                    cellContent.name === 'Assigned' ? 'badge-warning' :
                    cellContent.name === 'On The Way' ? 'badge-danger' :
                    cellContent.name === 'Confirmed' ? 'badge-dark' :
                    'badge-secondary'
                 }`
                  }>{cellContent.name}</span>)
            },
            sortCaret: sortCaret,
        },
        {
            dataField: "order_placed.created_at",
            text: "Date",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{datePipe(cellContent)}</span>)
            }
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                delete: false,
                edit: false,
                view: true,
                print: true,
                edit_delivery: true,
                viewItem: (id) => fetchOrderInfo(id),
                openEditProductPage: (id) => { props.history.push(`/banners/${id}/edit`) },
                openDeleteProductDialog: (id) => null,
                openPrintPage: (id) => fetchOrderDetail(id),
                editDelivery : (id) => editDeliveryData(id)
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];
    const [init] = useState(true);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [popup, setPopup] = useState(false)
    const [tempOrders, setTempOrders] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);
    const [loading, setLoading] = useState(true)
    const [selectedOrderPrint, setSelectedOrderPrint] = useState(0);
    const [deliveryModalShow, setDeliveryModalShow] = useState(false)
    const [id, setId] = useState()
    const [order_status_id, set_order_status_id] =useState()
    const [order_status_data, set_order_status_data] =useState()

    const [isloading, setIsloading] = useState(false)
    const [searchType, setSearchType] = useState([
        {
            label:'Order Id',
            value:'id'
        },
        {
            label:'Name',
            value:'name'
        },
        {
            label:'Mobile',
            value:'mobile'
        },
    ])


    const fetData = (id = null, params = {}) => {
        setLoading(true)
        Orders.get(id, params).then((response) => {
            try {
                setTempOrders([...response.orders])
                let orders = response.orders.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.orders.length
                setOrders(orders)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const fetOrderStatusData = () => {
        setLoading(true)
        Orders.getOrdersStatus().then((response) => {
            const list = response.orderStatuses.map((item) => (
                {
                    value : item.id,
                    label : item.name
                }
            ))
            set_order_status_data(list)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }
    useEffect(() => {
        (()=>{
            onTableChanged('page', _paginationOptions)
        })()
    }, [tempOrders])

    useEffect(() => {
        fetData()
        fetOrderStatusData()
    }, [init])

    useEffect(() => {
        handlePrint()
     }, [selectedOrderPrint]);

    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        let start = (pageNumber - 1) * sizePerPage
        let orders = tempOrders.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        setOrders(orders)
        setPaginationOptions(_paginationOptions)
    }
    const fetchOrderInfo = (id, params={}) => {
        setLoading(true)
        Orders.getById(id).then(response => {
            setSelectedOrder({ ...response.order })
            setPopup(true)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to fetch Order', type: 'error' })
        })
    }
    const fetchOrderDetail = (id) => {
        Orders.getById(id).then(response => {
            try {
                let OrderPrintValue = {... response.order }
                setSelectedOrderPrint(OrderPrintValue)   
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
           
        }).catch(err => {
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to fetch Order', type: 'error' })
        })
       
    }
    const handleClose = () => {
        setSelectedOrder({})
        setPopup(false)
    }
    const componentRef = useRef();
       
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    
    const editDeliveryData = (id) => {
        setId(id)
        const row_data = (orders.filter(item => item.id === id))[0]
        set_order_status_id(row_data?.order_status_id ? row_data?.order_status_id : "")
        setDeliveryModalShow(true)
    }

    const handleCloseDeliveryModal = () => {
        setDeliveryModalShow(false)
    }
       
    
    const EditOrder = (data, id) => {
        setLoading(true)
        Orders.updateOrderStatus(data, id).then(response => {
            props.openToaster({ open: true, message: response.message, type: 'success' })
            fetData()
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to update status', type: 'error' })
        })
    }


    const filterChanged = (filter) => {
        if (filter.filterType == null && filter.filterValue == null) {
            setTempOrders([])
            setOrders([])
            paginationOptions.totalSize = 0
            setPaginationOptions(paginationOptions)
            return fetData();
        }

        setIsloading(true)
        setLoading(true)

        Orders.get(null, {
            search_key:filter.filterType,
            search_value:filter.filterValue
        }).then((response) => {
            try {
                setTempOrders([...response.orders])
                let orders = response.orders.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.orders.length
                setOrders(orders)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setIsloading(false)
            setLoading(false)
        }).catch(err => {
            setIsloading(false)
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to fetch orders', type: 'error' })
        })
    }

    return (
        <>
        <Modal 
        id = {id}
        show={deliveryModalShow}
        handleClose={handleCloseDeliveryModal}
        EditOrder={EditOrder}
        order_status_id={order_status_id}
        order_status_data={order_status_data}
        />
          {selectedOrderPrint ?
           
            <div style={{display : "none"}}>
                <PrintOrderDetail data={selectedOrderPrint} 
                copyStyles={true}
                pageStyle='@page { size: auto; margin: 0mm; }
                }'
                ref={componentRef} /> 
                </div>
                : null
             }

            <Card>
                {loading && (<ModalProgressBar />)}
                <CardHeader title="Orders">
                        <CustomFilterComponent
                            searchType={searchType}
                            isloading={isloading}
                            filterChanged={filterChanged}
                        />
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <CommonTable
                        entities={orders}
                        onChange={onTableChanged}
                        paginationOptions={_paginationOptions}
                        columns={columns}
                        listLoading={loading}
                    />
                </CardBody>
            </Card>
            {(popup) && (
                <OrderDetails
                    data={selectedOrder}
                    handleClose={handleClose}
                    open={popup}
                />
            )}
        </>
    )
};


export const ListOrders = injectIntl(connect(null, toaster.actions)(_ListOrders))

