import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';

import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Reports as ReportsApi } from "../../utlis/API"
import { getFormatedDate } from "../../utlis/CommonMethods"
import { sortCaret } from "../../../_metronic/_helpers"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import { makeStyles } from '@material-ui/core/styles';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const _Reports = (props) => {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState(getFormatedDate((new Date()).setDate((new Date().getDate() - 30))))
    const [toDate, setTodate] = useState(getFormatedDate(new Date()))
    const [totalOrders, setTotalOrders] = useState({ orders: 0, revenue: 0 })
    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 }
        ],
        sizePerPage: 5,
        page: 1,
    };
    const columns = [
        {
            dataField: "id",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "store_id",
            text: "Store Name",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, index) => {
                if (row.store != null){
                    return (<span>{row.store.name}</span>)
                }
                return (<span>{cellContent}</span>)
            }
        },
        {
            dataField: "orders",
            text: "Orders",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "total_revenue",
            text: "Revenue",
            sort: true,
            sortCaret: sortCaret
        }
    ];

    const [init,] = useState(true);
    const [reports, setReports] = useState([]);
    const [tempReports, settempReports] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        onTableChanged('page', _paginationOptions)
    }, [tempReports])

    useEffect(() => {
        fetchData()
    }, [init])

    const fetchData = () => {
        setLoading(true)
        let d = {
            "created_from": fromDate,
            "created_to": toDate
        }
        ReportsApi.get(d).then((response) => {
            try {
                let totalData = {
                    orders: 0,
                    revenue: 0
                }

                response.orderReport.map((s, index) => {
                    totalData.orders += s.orders
                    totalData.revenue += parseFloat(s.total_revenue)
                    s.id = index + 1
                    return s
                })
                setTotalOrders({...totalData})
                settempReports([...response.orderReport])
                let reports = response.orderReport.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.orderReport.length
                setReports(reports)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }
    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        let start = (pageNumber - 1) * sizePerPage
        let reports = tempReports.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        setReports(reports)
        setPaginationOptions(_paginationOptions)
        
    }
    const handleDateChange = (ev) => {
        setFromDate(ev.target.value);
        let d = new Date(ev.target.value)
        d.setDate(d.getDate() + 30)
        setTodate(getFormatedDate(d))
        // fetchData()
    };
    const handleDateChangeForTodate = (ev) => {
        setTodate(ev.target.value);
        // fetchData()
    };
    const pickerMinDate = () => {
        let d = new Date(fromDate)
        d.setDate(d.getDate() + 1)
        return getFormatedDate(d)
    }
    return (
        <Card>
            {loading && (<ModalProgressBar />)}
            <CardHeader title="Reports">
                <CardHeaderToolbar>
                    <div className="date-filter">
                        <TextField
                            id="date1"
                            label="From"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleDateChange}
                            value={fromDate}
                            inputProps={{
                                min: "2020-01-01"
                            }}
                        />
                        <TextField
                            id="date2"
                            label="To"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: pickerMinDate()
                            }}
                            value={toDate}
                            onChange={handleDateChangeForTodate}
                        />
                        <OverlayTrigger
                            overlay={<Tooltip id="products-edit-tooltip">Apply filter</Tooltip>}
                        >
                            <span 
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={(ev) => {
                                    fetchData()
                                    ev.target.blur()
                                    // console.log(ev)
                                }}
                            >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                    />
                                </span>
                            </span>
                        </OverlayTrigger>
                    </div>
                    <div className="revenue-div">
                        <span className="label-span text-dark-50">
                            Total orders:
                        <span className="amount-span text-primary">{totalOrders.orders}</span>
                        </span>
                        <span className="label-span text-dark-50">
                            Total revenue:
                        <span className="amount-span text-primary">{totalOrders.revenue}</span>
                        </span>
                    </div>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <CommonTable
                    entities={reports}
                    onChange={onTableChanged}
                    paginationOptions={_paginationOptions}
                    columns={columns}
                    listLoading={loading}
                />
            </CardBody>
        </Card>
    )
};
export const Reports = injectIntl(connect(null, toaster.actions)(_Reports))

