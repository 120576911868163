import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { ProductTable } from "./ProTable";
import { Product } from "../../utlis/API"
import { useDispatch } from "react-redux";
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as alert from "../../../redux/reducer-extras/alertRedux"
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import SearchField from "react-search-field";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../_metronic/_partials/controls";

export const ListProducts = (props) => {
    console.log("props",props)
    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "20", value: 20 }
        ],
        sizePerPage: 20,
        page: props.location.state !== undefined && props.location.state !== null ? props.location.state.page : 1,
    };
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([]);
    const [tempProducts, setTempProducts] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);
    const [pageNum, setPageNum] = useState(1)
    const [searchValue, setSearchValue] = useState("");
    const imageView = (cellContent, row, rowIndex) => {
        return (
            <img src={cellContent} className="table-image" alt={cellContent} />
        )
    }
    const columns = [
        {
            dataField: "id",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "stock",
            text: "Stock",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span className="table-chip">{cellContent}</span>)
            }
        },
        {
            dataField: "mrp",
            text: "MRP",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "qty_label",
            text: "Qty Label",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "img",
            text: "Image",
            sort: true,
            sortCaret: sortCaret,
            formatter: imageView
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                edit: true,
                delete: true,
                openEditProductPage: (id) => { props.history.push(`/products/${id}/edit`) },
                openDeleteProductDialog: (id) => { deleteProduct(id) },
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    useEffect(()=>{
        onTableChanged('page', _paginationOptions)
    }, [tempProducts])
    useEffect(() => {
        reInitializeComponent()
    }, [props.location]);
    const proceedDelete = (id) => {
        Product.delete(id).then((res)=>{
            let _tmpProducts = [...tempProducts]
            let index = _tmpProducts.findIndex((e)=> e.id == id)
            if (index != -1) _tmpProducts.splice(index, 1)
            setTempProducts([..._tmpProducts])
            dispatch(toaster.actions.openToaster({ open: true, message: 'Product successfully deleted', type: 'success' }))
        }).catch(e=>{
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete product', type: 'error' }))
        })
    }
    const deleteProduct = (id) => {
        dispatch(alert.actions.showAlert({
            open: true,
            message: 'Are you sure you want to delete this product',
            type: 'confirm',
            title: 'Delete product',
            cb: (status) => {
                if (status) proceedDelete(id)
            }
        }))
    }
    const reInitializeComponent = () => {
        setLoading(true)
        Product.get().then((response) => {
            setLoading(false)
            setTempProducts([...response.product])
            let products = response.product.slice(0, paginationOptions.sizePerPage)
            paginationOptions.totalSize = response.product.length
            setProducts(products)
            setPaginationOptions(paginationOptions)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        setPageNum(pageNumber)
        let start = (pageNumber - 1) * sizePerPage
        let products = tempProducts.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        _paginationOptions.totalSize = tempProducts.length
        setProducts(products)
        setPaginationOptions(_paginationOptions)
    }

    const onSearchClick = (event) => {
        let list = tempProducts.filter(product => product.name.toLowerCase().includes(event.toLowerCase()))
            _paginationOptions.page = 1;
            _paginationOptions.totalSize = list.length;
            setPaginationOptions(_paginationOptions)
            setProducts(list)
    }

    const onEnter = (event) => {
        let list = tempProducts.filter(product => product.name.toLowerCase().includes(event.toLowerCase()))
            _paginationOptions.page = 1;
            _paginationOptions.totalSize = list.length;
            setPaginationOptions(_paginationOptions)
            setProducts(list)
    }

    const onChange = (event) => {
       if(event === ''){
           _paginationOptions.page = pageNum;
           let start = (pageNum - 1) * _paginationOptions.sizePerPage
        let products = tempProducts.slice(start, start + _paginationOptions.sizePerPage)
        _paginationOptions.totalSize = tempProducts.length
        setProducts(products)
        setPaginationOptions(_paginationOptions)
       }
    }

    return (
        <Card>
            {loading && (<ModalProgressBar />)}
            <CardHeader title="Products">
                <CardHeaderToolbar>
                <SearchField
                    placeholder="Search..."
                    onSearchClick={onSearchClick}
                    onEnter={onEnter}
                    onChange={onChange}
                    classNames="search-input"
                    />
                    &nbsp;&nbsp;
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => props.history.push({pathname : '/products/add',state: { page: pageNum }})}
                    >
                        Add new Product
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ProductTable
                    entities={products}
                    onChange={onTableChanged}
                    paginationOptions={_paginationOptions}
                    columns={columns}
                    listLoading={loading} />
            </CardBody>
        </Card>
    )
};


