import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { ProductToDealEditForm } from "./ProductToDealEditForm";
import { DealProduct, Deal } from "../../utlis/API";
import { Product } from "../../utlis/API";
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import { useDispatch } from "react-redux";

const initialData = {
  product_id: '',
}

export const AddProductToDeal = (props) => {
  const dispatch = useDispatch()
  const [init] = useState(true);
  const [data, setData] = useState(initialData);
  const [type, setType] = useState('add')
  const [dealId, setId] = useState(null)
  const [dealData, setDealData] = useState({});
  const [dealName, setDealName] = useState(null);
  const [saving, setSaving] = useState(false)
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true)

  const backToDealProductsList = () => props.history.goBack()
  const saveProductToDeal = (values) => {
    let data = {
        product_id: values.product_id
  }
    if (type == 'edit') data['_method'] = "PUT"
    setSaving(true)
    DealProduct.save(data, dealId).then(response => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Product successfully saved to Deal', type: 'success' }))
      if (type === 'add') resetbtnRef.current.click()
      else backToDealProductsList()
      setSaving(false)
    }).catch(err => {
      err = JSON.parse(err.message)
      let msg = loopErros(err.data.errors)
      if (msg == null) msg = 'Failed to save product to deal'
      dispatch(toaster.actions.openToaster({ open: true, message: msg, type: 'error' }))
      setSaving(false)
    })
  }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveCouponClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  useEffect(() => {
    try {
        getProducts()
        let id = props.match.params.id
        if (id !== undefined) fetchDealData(id)
        
    }
    catch (e) {
        console.log(e)
    }
  }, [init]);

  const fetchDealData = (id) => {
    setLoading(true)
     Deal.getById(id).then((response) => {
        setId(id)
        setLoading(false)
        setDealData({...response.deal})
        let dealname = response.deal.name
        setDealName(dealname)
    }).catch(err => {
        console.log(err)
        setLoading(false)
    })
}

  const getProducts = () => {
    Product.get().then(r => {
        let products = r.product.map(p => {
            return { value: p.id, label: p.name }
        })
        setProducts([...products])
        setLoading(false)
    }).catch(err => {
        console.log(err)
        setLoading(false)
    })
}

  const loopErros = (data) => {
    let keys = Object.keys(data)
    if (keys.length > 0) {
      if (Array.isArray(data[keys[0]])) {
        let message = data[keys[0]][0]
        if (message !== undefined) return message
      }
    }
    else return null
  }
  return (
    <Card>
      <CardHeader title = {dealName}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToDealProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            disabled={saving}
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCouponClick}
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <ProductToDealEditForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            products={products}
            resetbtnRef={resetbtnRef}
            save={saveProductToDeal}
          >
          </ProductToDealEditForm>
        </div>

      </CardBody>
    </Card>
  )
};
