// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, DatePickerField } from "../../../_metronic/_partials/controls";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from 'react-select'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getTargetPeriod } from "./AddTargets"
import {
    Card,
    CardBody
} from "../../../_metronic/_partials/controls";
let months = []

// Validation schema
const targetsSchema = Yup.object().shape({
    amount: Yup.number()
        .required("Amount is required")
        .min(0, 'Minmum value is 0')
});

const targetsMonthSchema = Yup.object().shape({
    amount: Yup.number()
        .required("Amount is required")
        .min(0, 'Minmum value is 0'),
    month: Yup.string()
        .required("Month is required")
});
const configureMonthOptions = () => {
    let d = new Date()
    let currentMonth = d.getMonth()
    let m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let remainingMonths = m.slice(currentMonth - 1, m.length)
    for (let i = d.getFullYear(); i <= d.getFullYear() + 5; i++) {
        if (i == d.getFullYear()) {
            remainingMonths.forEach((month) => {
                let c = ('0' + (m.indexOf(month) + 1)).slice(-2)
                months.push({
                    value: `${i}-${c}`,
                    label: `${i}-${month}`
                })
            })
        }
        else {
            m.forEach((month) => {
                let c = ('0' + (m.indexOf(month) + 1)).slice(-2)
                months.push({
                    value: `${i}-${c}`,
                    label: `${i}-${month}`
                })
            })
        }
    }
}
configureMonthOptions()
function TragetListingForm({
    data,
    targetTypes,
    save,
    addItem,
    deleteItem,
    index,
    length,
    targetType
}) {
    const [selectedType, setSelectedType] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})
    useEffect(() => {
        if (data.type != null && data.type != "") {
            let value = targetTypes.find((e) => e.value == data.type)
            if (value) setSelectedType(value)
        }
        if (data.month != null && data.month != "") {
            let value = months.find((e) => e.value == data.month)
            if (value) setSelectedMonth(value)
        }
    }, [data])

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={targetType == 'day' ? targetsSchema : targetsMonthSchema}
                onSubmit={(values) => {
                    values.type = targetType
                    save(values);
                }}
            >
                {({ handleSubmit, setFieldValue, errors, values }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="row">
                                {(index == 0) && (
                                    <div className="col-12 row mt-3">
                                        <div className="col-4">
                                            <label>{targetType == 'day' ? 'Date' : 'Month'}</label>
                                        </div>
                                        <div className="col-4">
                                            <label>Amount</label>
                                        </div>
                                    </div>
                                )}
                                <div className="form-group col-12 row mt-2">
                                    {(targetType == 'day') &&
                                        (<div className="col-4">
                                            <DatePickerField
                                                name="period"
                                                dateFormat="yyyy-MM-d"
                                                onChange={(event) => console.log(event)}
                                            />
                                        </div>)}
                                    {(targetType == 'month') &&
                                        (<div className="col-4">
                                            <AutoComplete
                                                value={selectedMonth}
                                                name="month"
                                                options={months}
                                                onChange={(value) => setFieldValue("month", value)} />
                                        </div>)}
                                    <div className="col-3">
                                        <Field
                                            name="amount"
                                            component={Input}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-4"
                                        style={{
                                            "display": "flex",
                                            "justifyContent": "flex-start",
                                            "alignItems": "center"
                                        }}>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="products-edit-tooltip">Save</Tooltip>}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit()}
                                            >
                                                save
                                            </button>
                                        </OverlayTrigger>
                                        {/* <button
                                            type="button"
                                            onClick={() => {
                                                handleSubmit()
                                            }}
                                        >Save</button> */}
                                        {/* {(values.id) && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    deleteItem(values)
                                                }}
                                            >Delete</button>
                                        )} */}
                                    </div>
                                </div>
                            </div>

                        </Form>
                    </>
                )}
            </Formik >
        </>
    )
}

export function TargetEditForm({
    data,
    stores,
    save,
    targetTypes,
    storeChanged,
    deleteTraget,
    store
}) {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newtabIndex) => {
        setTabIndex(newtabIndex);
    }
    return (
        <>
            <div className="form-group col-12 row">
                <div className="col-lg-6">
                    <label>Select store</label>
                    {(store.value == undefined) &&
                        (<Select
                            placeholder="Select store"
                            options={stores}
                            onChange={(value) => {
                                // setSelectedStoreValue(value)
                                storeChanged(value.value)
                            }} />)
                    }
                    {(store.value != undefined) &&
                        (<Select
                            placeholder="Select store"
                            value={store}
                            options={stores}
                            onChange={(value) => {
                                // setSelectedStoreValue(value)
                                storeChanged(value.value)
                            }} />)
                    }
                </div>
            </div>
            <div className="col-12">
                <Card style={{
                    "background": tabIndex == 0 ? "#f3f2f4" : "#eaffed"
                }}>
                    <CardBody className="contains-tabs">
                        <Paper square>
                            <Tabs value={tabIndex} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                                <Tab label="Month" />
                                <Tab label="Day" />
                            </Tabs>
                        </Paper>
                        {tabIndex === 0 && (
                            data.targets.map((target, index) => {
                                if (target.type == 'day') return null
                                return (
                                    <TragetListingForm
                                        key={'target-' + index}
                                        data={target}
                                        targetTypes={targetTypes}
                                        save={((target) => {
                                            target.storeId = store.value
                                            save(target)
                                        })}
                                        index={index}
                                        length={data.targets.length}
                                        targetType="month"
                                        deleteItem={deleteTraget}
                                    />
                                )
                            })
                        )}
                        {tabIndex === 1 && (
                            data.targets.map((target, index) => {
                                if (target.type == 'month') return null
                                return (
                                    <TragetListingForm
                                        key={'target-' + index}
                                        data={target}
                                        targetTypes={targetTypes}
                                        save={((target) => {
                                            target.storeId = store.value
                                            save(target)
                                        })}
                                        index={index}
                                        length={data.targets.length}
                                        targetType="day"
                                        deleteItem={deleteTraget}
                                    />
                                )
                            })
                        )}
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
