import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Orders, Locations, TimeSlots, Drivers, Routes } from "../../utlis/API"
import { datePipe } from "../../utlis/CommonMethods"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
import { Formik } from "formik";
import * as alert from "../../../redux/reducer-extras/alertRedux";
import { DispatchPopUP } from "./DispatchPopUP"
const _ListDispatches = (props) => {
    const dispatch = useDispatch()
    const paginationOptions = {
        custom: true,
        totalSize: 0,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 }
        ],
        sizePerPage: 5,
        page: 1,
    };
    const columns = [
        {
            dataField: "slNo",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "id",
            text: "Order ID",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{'Order-' + cellContent}</span>)
            }
        },
        {
            dataField: "store_name",
            text: "Store",
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: "total_quantity",
            text: "Qty",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "total_mrp",
            text: "MRP Total",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "amount_to_be_paid",
            text: "Paid amount",
            sort: true,
            sortCaret: sortCaret,
        },
        // {
        //     dataField: "current_status",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cellContent, row, rowIndex) => {
        //         return (<span>{cellContent.name}</span>)
        //     },
        //     sortCaret: sortCaret,
        // },
        {
            dataField: "created_at",
            text: "Date",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{datePipe(cellContent)}</span>)
            }
        }

    ];

    const [init] = useState(true);
    const [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [popup, setPopup] = useState(false)
    const [tempOrders, setTempOrders] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);
    const [loading, setLoading] = useState(true)
    const [isloading, setIsloading] = useState(false)
    const [locations, setLocations] = useState([])
    const [routes, setRoutes] = useState([])
    const [timeSlots, setTimeSlots] = useState([])
    const [drivers, setDrivers] = useState([])
    const [selectedOrdersTmp, setSelectedOrdersTmp] = useState([])
    const [currentFilter, setCurrentFilter] = useState({ location: null, timeSlot: null })

    useEffect(() => {
        (() => {
            onTableChanged('page', _paginationOptions)
        })()
    }, [tempOrders])
    useEffect(() => {
        setLoading(true)
        // getOrders()
        getRoutes()
        // getLocations()
        getTimeSlots()
        getDrivers()
    }, [init])
    const getOrders = () => {
        Orders.get().then((response) => {
            try {
                setTempOrders([...response.orders])
                let orders = response.orders.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.orders.length
                setOrders(orders)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
        })
    }
    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        let start = (pageNumber - 1) * sizePerPage
        let orders = tempOrders.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        setOrders(orders)
        setPaginationOptions(_paginationOptions)
    }
    const fetchOrderInfo = (id) => {
        setLoading(true)
        Orders.getById(id).then(response => {
            // setSelectedOrder({ ...response.order })
            setPopup(true)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to fetch Order', type: 'error' })
        })
    }
    const handleClose = () => {
        // setSelectedOrder({})
        setPopup(false)
    }

    const getLocations = () => {
        Locations.get().then(r => {
            let locations = r.locations.map(d => {
                return { value: d.id, label: d.name }
            })
            setLocations([...locations])
            setLoading(false)
        }).catch(err => {
            console.log("location fetching")
            console.log(err)
        })
    }

    const getRoutes = () => {
        Routes.get().then(r => {
            console.log("r",r)
            let routes = r.Route.map(d => {
                return { value: d.id, label: d.route_name }
            })
            console.log("r",routes)
            setRoutes([...routes])
            setLoading(false)
        }).catch(err => {
            console.log("routes fetching")
            console.log(err)
        })
    }


    const getTimeSlots = () => {
        TimeSlots.get().then(r => {
            let timeSlots = r.time_slots.map(d => {
                return { value: d.id, label: d.name }
            })
            setTimeSlots([...timeSlots])
            setLoading(false)
        }).catch(err => {
            console.log("timeslots fetching")
            console.log(err)
        })
    }
    const getDrivers = () => {
        Drivers.get().then(r => {
            let drivers = r.driverList.map(d => {
                return { value: d.driver.id, label: d.name }
            })
            setDrivers([...drivers])
            setLoading(false)
        }).catch(err => {
            console.log("timeslots fetching")
            console.log(err)
        })
    }
    const filterChanged = (filter) => {
        setCurrentFilter(filter)
        if (filter.route == null && filter.timeSlot == null) {
            // getOrders()
            setTempOrders([])
            setOrders([])
            paginationOptions.totalSize = 0
            setPaginationOptions(paginationOptions)
            return
        }
        setIsloading(true)
        setLoading(true)

        Orders.getDispatches(filter.timeSlot, filter.route).then((response) => {
            try {
                setTempOrders([...response.orders])
                let orders = response.orders.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.orders.length
                setOrders(orders)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setIsloading(false)
            setLoading(false)
        }).catch(err => {
            setIsloading(false)
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to fetch orders', type: 'error' })
        })
    }
    const setTmpSelection = (index) => {
        let id = selectedOrders.map(e => e.id)
        // setSelectedOrdersTmp
    }
    const handleOnSelect = (row, status, rowindex, d) => {

        let index = selectedOrders.findIndex(id => id == row.id)
        if (index != -1) selectedOrders.splice(index, 1)
        else selectedOrders.push(row.id)
        setSelectedOrders([...selectedOrders])

    }
    const handleOnSelectAll = (status, a, b, c, d) => {
        console.log(status, a, b, c, d)
        if (status) {
            let ids = tempOrders.map(r => r.id)
            setSelectedOrders([...ids])
        }
        else setSelectedOrders([])
    }
    const dispatchOrders = () => {
        dispatch(alert.actions.showAlert({
            open: true,
            message: 'Are you sure you want to dispatch this slected orders?',
            type: 'confirm',
            title: 'Dispatch orders',
            cb: (status) => {
                if (status) setPopup(true)
            }
        }))
    }
    const handleSuccess = (driverId) => {
        let obj = {
            "_method": "put",
            orders: [...selectedOrders]
        }
        Drivers.assign(driverId, obj).then((res) => {
            setSelectedOrders([])
            props.openToaster({ open: true, message: 'Orders are successfully assigned', type: 'success' })
            setPopup(false)
            filterChanged(currentFilter)
        }).catch(err => {
            props.openToaster({ open: true, message: 'Failed to assign a driver', type: 'error' })
            setPopup(false)
        })
    }
    return (
        <>
            <Card>
                {loading && (<ModalProgressBar />)}
                <CardHeader title="Orders">
                    {/* <CardHeaderToolbar>
                    </CardHeaderToolbar> */}
                    {selectedOrders.length > 0 && (

                        <button
                            type="button"
                            className="btn btn-primary ml-2 dispatch-orders"
                            onClick={() => dispatchOrders()}>
                            Dispatch
                        </button>
                    )}
                    <FilterComponent
                        // locations={locations}
                        routes={routes}
                        timeSlots={timeSlots}
                        isloading={isloading}
                        filterChanged={filterChanged}
                    />
                </CardHeader>
                <CardBody>
                    <CommonTable
                        entities={orders}
                        onChange={onTableChanged}
                        paginationOptions={_paginationOptions}
                        columns={columns}
                        listLoading={loading}
                        selectRow={true}
                        handleOnSelect={handleOnSelect}
                        handleOnSelectAll={handleOnSelectAll}
                        selected={selectedOrders}
                    />
                </CardBody>
                {(popup) && (
                    <DispatchPopUP
                        data={selectedOrders}
                        handleClose={handleClose}
                        handleSuccess={handleSuccess}
                        drivers={drivers}
                        open={popup}
                    />
                )}
            </Card>
        </>
    )
};
export const ListDispatches = injectIntl(connect(null, toaster.actions)(_ListDispatches))


const FilterComponent = (props) => {
    const [location, setLocation] = useState(null)
    const [route, setRoute] = useState(null)
    const [timeSlot, setTimeSlot] = useState(null)
    const [tmpLocation, setTmpLocation] = useState({})
    const [tmpTimeSlot, setTmpTimeSlot] = useState({})
    
    const dispatch = useDispatch()

    const applyFilter = () => {
        if (props.isloading) return
        if (route == null) {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Select a route.', type: 'error' }))
            return
        }
        if (timeSlot == null) {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Select a time slot.', type: 'error' }))
            return
        }
        props.filterChanged({ route: route, timeSlot: timeSlot })

    }

    const clearFilter = () => {
        setRoute(null)
        setLocation(null)
        setTimeSlot(null)
        setTmpLocation({})
        setTmpTimeSlot({})
        props.filterChanged({ route: null, timeSlot: null })
        console.log(route)
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={props.data}
                onSubmit={(values) => {
                    // applyFilter(values);
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, values }) => (
                    <>

                        <div className="dispatch-filter-row">
                            <div className="coloums first">
                                <AutoComplete
                                    value={tmpLocation}
                                    label="Route"
                                    name="route"
                                    options={props.routes}
                                    onChange={(value) => setRoute(value)}
                                />
                            </div>
                            <div className="coloums">
                                <AutoComplete
                                    value={tmpTimeSlot}
                                    label="time slots"
                                    name="timeSlot"
                                    options={props.timeSlots}
                                    onChange={(value) => setTimeSlot(value)}
                                />
                            </div>
                            <div className="btn-ctnr">
                                <button
                                    type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={applyFilter}
                                >
                                    Apply filter
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary-light ml-2"
                                    onClick={clearFilter}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}