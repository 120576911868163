import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../_metronic/_partials/controls";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from "../../../_metronic/_helpers"
export function DriverTable(props) {
    const { entities, listLoading } = props 
    return (
        <>
            <PaginationProvider pagination={paginationFactory(props.paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="id"
                                data={!entities ? [] : entities}
                                columns={props.columns}
                                // defaultSorted={[{ dataField: "slNo", order: "asc" }]}
                                onTableChange={props.onChange}
                                // selectRow={()=>{}}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}