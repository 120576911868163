import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Coupon } from "../../utlis/API";
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import * as alert from "../../../redux/reducer-extras/alertRedux";
const _ListCoupons = (props) => {
  const dispatch = useDispatch()
  const paginationOptions = {
    custom: true,
    totalSize: 100,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: 5,
    page: 1,
  };
  const columns = [
    {
      dataField: "id",
      text: "SL NO",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row, rowIndex) => {
        return (<span>{rowIndex + 1}</span>)
      }
    },
    {
      dataField: "coupon_code",
      text: "Coupon Code",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "discount_type",
      text: "Discount Type",
      sort: true,
      sortCaret: sortCaret,
    },
    {
        dataField: "valid_untill",
        text: "Valid Until",
        sort: true,
        sortCaret: sortCaret,
      },
      {
        dataField: "minimum_cart_amount",
        text: "Cart Amount",
        sort: true,
        sortCaret: sortCaret,
      },
      {
        dataField: "minimum_discount_allowed",
        text: "Discount Allowed",
        sort: true,
        sortCaret: sortCaret,
      },
      {
        dataField: "products_applicable",
        text: "Products Applicable",
        sort: true,
        sortCaret: sortCaret,
      },
      {
        dataField: "usage_limit",
        text: "Usage Limit",
        sort: true,
        sortCaret: sortCaret,
      },
      {
        dataField: "usage_limit_per_user",
        text: "Usage Limit per user",
        sort: true,
        sortCaret: sortCaret,
      },
      {
        dataField: "usage_count",
        text: "Usage Count",
        sort: true,
        sortCaret: sortCaret,
      },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditProductPage: (id) => { 
          // console.log("Edit")
          props.history.push(`/coupons/${id}/edit`)
         },
        openDeleteProductDialog: (id) => { 
          deleteItem(id)
          console.log('delete')
         },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const [init, setInit] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [products, setProducts] = useState([]);
  const [tempCoupons, setTempCoupons] = useState([]);
  const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    onTableChanged('page', _paginationOptions)
  }, [tempCoupons])
  useEffect(() => {
    setLoading(true)
    Coupon.get().then((response) => {
      try {
        setTempCoupons([...response["All coupons"]])
        let coupons = response["All coupons"].slice(0, paginationOptions.sizePerPage)
        paginationOptions.totalSize = response["All coupons"].length
        setCoupons(coupons)
        setPaginationOptions(paginationOptions)
      }
      catch (e) {
        console.log(e)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
    })


  }, [init])
  const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    let start = (pageNumber - 1) * sizePerPage
    let coupons = tempCoupons.slice(start, start + sizePerPage)
    _paginationOptions.sizePerPage = sizePerPage
    _paginationOptions.page = pageNumber
    setCoupons(coupons)
    setPaginationOptions(_paginationOptions)
  }
  const deleteItem = (id) => {
    dispatch(alert.actions.showAlert({
      open: true,
      message: 'Are you sure you want to delete this coupon',
      type: 'confirm',
      title: 'Delete Coupon',
      cb: (status) => {
        if (status) proceedDelete(id)
      }
    }))
  }
  const proceedDelete = (id) => {
    Coupon.delete(id).then((res) => {
      let _tempCoupons = [...tempCoupons]
      let index = _tempCoupons.findIndex((e) => e.id == id)
      if (index != -1) _tempCoupons.splice(index, 1)
      setTempCoupons([..._tempCoupons])
      dispatch(toaster.actions.openToaster({ open: true, message: 'Coupon successfully deleted', type: 'success' }))
    }).catch(e => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete coupon', type: 'error' }))
    })
  }
  return (
    <Card>
      {loading && (<ModalProgressBar />)}
      <CardHeader title="Coupons">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/coupons/add')}
          >
            Add new coupon
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CommonTable
          entities={coupons}
          onChange={onTableChanged}
          paginationOptions={_paginationOptions}
          columns={columns}
          listLoading={loading}
        />
      </CardBody>
    </Card>
  )
};
export const ListCoupons = injectIntl(connect(null, toaster.actions)(_ListCoupons))

