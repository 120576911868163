/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { openEditProductPage, openDeleteProductDialog,openPrintPage, ...others }
) => (
        <> 
        {others.edit_delivery === true && (
                <OverlayTrigger
                    overlay={<Tooltip id="products-edit-tooltip">Edit Order Status</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => others.editDelivery(row.id)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/General/Delivery.svg")}
                            />
                        </span>
                    </a>
                </OverlayTrigger>
            ) }
            {others.view === true && (
                <OverlayTrigger
                    overlay={<Tooltip id="products-edit-tooltip">View</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => others.viewItem(row.id)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                            />
                        </span>
                    </a>
                </OverlayTrigger>
            ) }
            {others.edit !== false && (
                <OverlayTrigger
                    overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => openEditProductPage(row.id)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                        </span>
                    </a>
                </OverlayTrigger>
            ) }
            {others.print !== false && (
                    <OverlayTrigger
                        overlay={<Tooltip id="products-edit-tooltip">Print</Tooltip>}
                    >
                        <a
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => openPrintPage(row.id)}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg")}
                                />
                            </span>
                        </a>
                    </OverlayTrigger>
                ) }
            <> </>
            {others.delete !== false && (
                <OverlayTrigger
                    overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={() => openDeleteProductDialog(row.id)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                        </span>
                    </a>
                </OverlayTrigger>
            )}
        </>
    );
