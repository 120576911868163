import React, {useState, useRef, useEffect} from "react";
import {Button, Modal} from 'react-bootstrap';
import {EditForm} from './Form'


export default function App (props) {

    const {show, handleClose, id, EditOrder, order_status_id, order_status_data} = props
    const btnRef = useRef();
    

    const saveProductClick = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    return (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Order Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditForm 
                btnRef={btnRef}
                handleClose={handleClose}
                id={id}
                EditOrder={EditOrder}
                order_status_id={order_status_id}
                order_status_data={order_status_data}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" 
                onClick={saveProductClick} 
                >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
    )
}