import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CouponEditForm } from "./CouponEditForm";
import { Coupon } from "../../utlis/API";
import { Product } from "../../utlis/API";
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import { useDispatch } from "react-redux";

const initialData = {
  coupon_code: '',
  discount_type: [{value : "Flat"}],
  minimum_cart_amount: '',
  minimum_discount_allowed: '',
  products_applicale: [],
  usage_limit: '',
}
const   discount_types = 
  [{label : 'Flat', value : 'flat'},
  {label : 'Percentage', value : 'percentage'}]

// const initialData = {
//   description: "Test",
//   name: "New",
//   userEmail: "aj@yopmail.com",
//   userMobile: "1234567890",
//   userName: "asa"
// }
export const AddCoupons = (props) => {
  const dispatch = useDispatch()
  const [init] = useState(true);
  const [data, setData] = useState(initialData);
  const [type, setType] = useState('add')
  const [storeId, setId] = useState(null)
  const [saving, setSaving] = useState(false)
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true)
  const [saveClick, setSaveClick] = useState(false)

  const backToCouponsList = () => props.history.goBack()
  const saveCoupon = (values) => {
    setSaveClick(false)
    let data = {
      coupon_code: values.coupon_code,
      discount_type: values.discount_type,
      minimum_cart_amount: values.minimum_cart_amount,
      minimum_discount_allowed: values.minimum_discount_allowed,
      products_applicable: values.products_applicable,
      usage_limit: values.usage_limit,
      valid_untill: values.valid_untill
  }
    if (type == 'edit') data['_method'] = "PUT"
    setSaving(true)
    Coupon.save(data, storeId).then(response => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Coupon successfully saved', type: 'success' }))
      if (type === 'add') resetbtnRef.current.click()
      else backToCouponsList()
      setSaving(false)
    }).catch(err => {
      err = JSON.parse(err.message)
      let msg = loopErros(err.data.errors)
      if (msg == null) msg = 'Failed to save coupon'
      dispatch(toaster.actions.openToaster({ open: true, message: msg, type: 'error' }))
      setSaving(false)
    })
  }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveCouponClick = () => {
    setSaveClick(true)
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  useEffect(() => {
    reInitializeComponent() 
    getProducts()
  }, [init]);

  const reInitializeComponent = async () => { 
    try {
      let id = props.match.params.id
      if (id !== undefined) fetchData(id)
    }
    catch (e) {
      console.log(e)
    }
  }
  const getProducts = () => {
    Product.get().then(r => {
        let products = r.product.map(p => {
            return { id: p.id, name: p.name }
        })
        setProducts([...products])
        setLoading(false)
    }).catch(err => {
        console.log(err)
        setLoading(false)
    })
}
  const fetchData = (id) => {
    Coupon.getById(id).then((r) => {
      setId(id)
      const data = {
        coupon_code: r.coupon.coupon_code,
        discount_type: r.coupon.discount_type,
        minimum_cart_amount: r.coupon.minimum_cart_amount,
        minimum_discount_allowed: r.coupon.minimum_discount_allowed,
        products_applicable: r.coupon.products_applicable,
        usage_limit: r.coupon.usage_limit,
        valid_untill: r.coupon.valid_untill,
      }
      setType('edit')
      setData({ ...data })
    }).catch(e => {
      // dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to get Store', type: 'error' })
    })
  }


  const loopErros = (data) => {
    let keys = Object.keys(data)
    if (keys.length > 0) {
      if (Array.isArray(data[keys[0]])) {
        let message = data[keys[0]][0]
        if (message !== undefined) return message
      }
    }
    else return null
  }
  return (
    <Card>
      <CardHeader title="Add coupons">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToCouponsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            disabled={saving}
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCouponClick}
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <CouponEditForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            saveClick={saveClick}
            discount_types={discount_types}
            products={products}
            resetbtnRef={resetbtnRef}
            save={saveCoupon}
          >
          </CouponEditForm>
        </div>

      </CardBody>
    </Card>
  )
};
