// import * as toaster from "./reducer-extras/toasterRedux";
import * as alert from "./reducer-extras/alertRedux";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `bearer ${authToken}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(res => {
    let response = res.data
    return response;
  }, (err) => {
    if (err.response) {
      if (401 === err.response.status) {
        store.dispatch(alert.actions.showAlert({
          open: true,
          message: 'This session has been expired. Please login and continue',
          type: 'alert',
          title: 'Session expired',
          cb: () => {
            window.location = '/logout';
          }
        }))
      }
    }
    let errResponse = JSON.stringify(err.response)
    let error = new Error(errResponse)
    throw error
  });
}
