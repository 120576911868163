import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { AddTargets } from "./AddTargets"
import { ListTargets } from "./ListTargets"


export const Targets = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Targets");
    

    return (
        <Switch>
            <Redirect
                exact={true}
                from="/targets"
                to="/targets/list"
            />
            <ContentRoute from="/targets/add" component={AddTargets} />
            <ContentRoute from="/targets/list" component={ListTargets} />
            <ContentRoute from="/targets/:id/edit" component={AddTargets} />
        </Switch>
    );
};
