import React, { useEffect } from "react";
import {
    Snackbar,
    SnackbarContent,
    IconButton
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { amber, green } from "@material-ui/core/colors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Tost from "../../../../redux/reducer-extras/toasterRedux";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};
const useStyles21 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
}));

const toasterTimeout = 3000

function MySnackbarContentWrapper(props) {
    const classes = useStyles21();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
}
let timeout
export function Toaster() {
    const dispatch = useDispatch()
    const toaster = useSelector(
        ({ toaster }) => toaster,
        shallowEqual
    );
    useEffect(() => {
        if (toaster.open) {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                dispatch(Tost.actions.closeToaster({}))
            }, 3000)
        }
    }, [toaster])
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            open={toaster.open}
            autoHideDuration={toasterTimeout}
            onClose={() => {}}
        >
            <MySnackbarContentWrapper
                // onClose={() => console.log('close')}
                variant={toaster.type}
                message={toaster.message}
            />
        </Snackbar>
    )
}