import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CatTable } from "./CatTable";
import { Category } from "../../utlis/API"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
const _ListCats = (props) => {

  const paginationOptions = {
    custom: true,
    totalSize: 100,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: 5,
    page: 1,
  };
  const imageView = (cellContent, row, rowIndex,) => {
    return (
      <img src={cellContent} className="table-image" alt={cellContent}/>
    )
  }
  const columns = [
    {
      dataField: "id",
      text: "SL NO",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row, rowIndex) => {
        return (<span>{rowIndex + 1}</span>)
      }
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "img",
      text: "Image",
      sort: true,
      sortCaret: sortCaret,
      formatter: imageView
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        delete:false,
        openEditProductPage: (id) => { props.history.push(`/categories/${id}/edit`) },
        openDeleteProductDialog: () => { console.log('openEditProductPage') },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const [init,] = useState(true);
  const [categories, setCategories] = useState([]);
  const [tempCategories, setTempCategories] = useState([]);
  const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    Category.get().then((response) => {
      try {
        setTempCategories([...response.productCategories])
        let categories = response.productCategories.slice(0, paginationOptions.sizePerPage)
        paginationOptions.totalSize = response.productCategories.length
        setCategories(categories)
        setPaginationOptions(paginationOptions)
      }
      catch (e) {
        console.log(e)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
    })

  }, [init])
  const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    let start = (pageNumber - 1) * sizePerPage
    let categories = tempCategories.slice(start, start + sizePerPage)
    _paginationOptions.sizePerPage = sizePerPage
    _paginationOptions.page = pageNumber
    setCategories(categories)
    setPaginationOptions(_paginationOptions)
  }
  return (
    <Card>
      <CardHeader title="Category list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/categories/add')}
          >
            Add new Category
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CatTable
          entities={categories}
          onChange={onTableChanged}
          paginationOptions={_paginationOptions}
          columns={columns}
          listLoading={loading}
        />
      </CardBody>
    </Card>
  )
};
export const ListCats = injectIntl(connect(null, toaster.actions)(_ListCats))

