
import React, {useState} from 'react';
import { CommonTable } from "../../page-components/CommonTable";
import { sortCaret } from "../../../../_metronic/_helpers"
import moment from 'moment'

export default function ListTable (props) {
    const [userTypes, setUserTypes] = useState([]);
    const [tempUserTypes, setTempUserTypes] = useState([]);

    const { data } = props

    
  React.useEffect(() => {
    onTableChanged('page', _paginationOptions)
  }, [tempUserTypes])

    React.useEffect(() => {
        const list = data.orders.map((item, index) => {
            item['sl'] = index + 1
            item['date'] = moment(item.created_at).format("DD/MM/YYYY");
            return(item)
        })
        setUserTypes(list)
        setTempUserTypes([...list])
        paginationOptions.totalSize = list.length
        setPaginationOptions(paginationOptions)
    }, [])

    const paginationOptions = {
      custom: true,
      totalSize: 100,
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "30", value: 30 }
      ],
      sizePerPage: 30,
      page: 1,
    };
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);
    
  const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    let start = (pageNumber - 1) * sizePerPage
    let userTypes = tempUserTypes.slice(start, start + sizePerPage)
    _paginationOptions.sizePerPage = sizePerPage
    _paginationOptions.page = pageNumber
    setUserTypes(userTypes)
    setPaginationOptions(_paginationOptions)
  }

    const columns = [
      {
        dataField: "sl",
        text: "SL NO",
        sort: true,
        sortCaret: sortCaret,
        formatExtraData: ({ rowIndex }) => {
          return (<span>{rowIndex + 1}</span>)
        }
      },
      {
        dataField: "id",
        text: "Order Id",
        sort: true,
        headerAlign: 'right',
        align: 'right',
        sortCaret: sortCaret,
      },
      {
        dataField: "date",
        text: "Order Date",
        sort: true,
        headerAlign: 'right',
        align: 'right',
        sortCaret: sortCaret,
      },
      {
        dataField: "amount_to_be_paid",
        text: "Total Amount",
        sort: true,
        headerAlign: 'right',
        align: 'right',
        sortCaret: sortCaret,
      },
    ];

    return (
        <CommonTable
          entities={userTypes}
          onChange={onTableChanged}
          paginationOptions={_paginationOptions}
          columns={columns}
        />
    )

}