
export const actionTypes = {
    open: "[Toaster open] Action",
    close: "[Toaster close] Action"
};

const initialToastState = {
    open: false,
    message: null,
    type: 'success'
};

export const reducer = (state = initialToastState, action) => {
    switch (action.type) {
        case actionTypes.open: {
            return { ...action.payload };
        }
        case actionTypes.close: {
            return initialToastState;
        }
        default:
            return state;
    }
}


export const actions = {
    openToaster: message => ({ type: actionTypes.open, payload: { ...message } }),
    closeToaster: message => ({ type: actionTypes.close, payload: { ...message } })
};
