// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect, useRef, } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";


// Validation schema
const ProductToDealEditSchema = Yup.object().shape({
    product_id: Yup.string()
        .required("Product is required"),
});

export function ProductToDealEditForm({
    products,
    btnRef,
    resetbtnRef,
    save,
    data
}) {
    const [init] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState({});

    useEffect(() => {
        let unmounted = false;
     
        if (products && data.product_id != null && data.product_id != "") {
            let value =  products.find((e) => e.value === data.product_id)
            if (value) setSelectedProducts(value)
        }
        return () => { unmounted = true };
    }, 
    [data, products])

    const ref = useRef(null);
    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={ProductToDealEditSchema}
                onSubmit={(values) => {
                    save(values);
                }}
                innerRef={ref}
            >
                {({ handleSubmit, resetForm, setFieldValue }) => (
                    <>

                        <Form className="form form-label-right">
                            <div className="row">
                                <div className="col-lg-12 px-md-5">
                                <AutoComplete
                                        value={selectedProducts}
                                        label="Products"
                                        name="product_id"
                                        options={products}
                                        onChange={(value) => setFieldValue("product_id", value)} />
                                </div>
                            </div>
                            
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => {
                                    setSelectedProducts({})
                                }}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
