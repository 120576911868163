// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from "../../../../_metronic/_partials/controls";
import { AutoComplete } from "../../../../_metronic/_partials/controls/forms/AutoComplete";


// Validation schema
const ProductEditSchema = Yup.object().shape({
    order_status_id : Yup.string()
        .required("Order Status is required")
});

export function EditForm({
    btnRef,
    EditOrder,
    handleClose,
    id,
    order_status_id,
    order_status_data
}) {

    const initialValues = {
        order_status_id : order_status_id
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={ProductEditSchema}
                onSubmit={(values) => {
                    handleClose()
                    EditOrder(values, id)
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, values, touched, dirty, ...others }) => (
                    <>
                        <Form className="form form-label-right">
                                    <div className="form-group row col-lg-10 p-0">
                                        <div className="col-lg-12">
                                            <AutoComplete
                                                value={(order_status_data.filter((item) => values.order_status_id == item.value))[0] ? (order_status_data.filter((item) => values.order_status_id == item.value))[0] : ''}
                                                label="Order Status"
                                                name="order_status_id"
                                                options={order_status_data}
                                                onChange={(value) => setFieldValue("order_status_id", value)} />
                                        </div>
                                    </div>
                                    <button
                                        disabled={!dirty}
                                        type="button"
                                        style={{ display: "none" }}
                                        ref={btnRef}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
