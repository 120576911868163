import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { connect } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux";

import { BannerEditForm } from "./BannerEditForm";
import { Banners } from "../../utlis/API"
import { injectIntl } from "react-intl";

export const _AddBanners = (props) => {
    const [data, setData] = useState({ title: '', description: '', image: null });
    const [init, ] = useState(true);
    const [saving, setSaving] = useState(false);
    const [type, setType] = useState('add')
    const [banId, setId] = useState(null)
    const back = () => {
        props.history.push('/banners/list')
    }

    const save = (values) => {
        setSaving(true)
        let formdata = new FormData()
        formdata.append('title', values.title)
        formdata.append('desc', values.description)
        if (typeof values.image != 'string') formdata.append('img', values.image)
        if (type === 'edit') formdata.append('_method', 'PUT')
        Banners.save(formdata, banId).then((res) => {
            props.openToaster({ open: true, message: 'Banner successfully saved', type: 'success' })
            if (type === 'add') resetbtnRef.current.click()
            else back()
            setSaving(false)
        }).catch(err => {
            setSaving(false)
            props.openToaster({ open: true, message: 'Failed to save Banner', type: 'error' })
            console.log(err)
        })
    }
    const btnRef = useRef();
    const resetbtnRef = useRef()
    const saveClick = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };
    const resetForm = () => {
        if (resetbtnRef && resetbtnRef.current) {
            resetbtnRef.current.click()
        }
    }
    useEffect(() => {
        try {
            let id = props.match.params.id
            if (id !== undefined) fetchData(id)
        }
        catch (e) {
            console.log(e)
        }
    }, [init])
    const fetchData = (id) => {
        Banners.getById(id).then((r) => {
            setId(id)
            r.banner.description = r.banner.desc
            if (r.banner.img === '') r.banner.img = 0
            r.banner.image = r.banner.img
            delete r.banner.desc
            delete r.banner.img
            setData(r.banner)
            setType('edit')
        }).catch(e => {
            props.openToaster({ open: true, message: 'Failed to get Banner', type: 'error' })
        })
    }
    return (
        <Card>
            {saving && (<ModalProgressBar />)}
            <CardHeader title={type !== 'edit' ? 'Add Banners' : 'Edit Banner'}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={back}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left"></i>
              Back
            </button>
                    {`  `}
                    <button
                        className="btn btn-light ml-2"
                        onClick={resetForm}>
                        <i className="fa fa-redo"></i>
              Reset
            </button>
                    {`  `}
                    <button
                        disabled={saving}
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={saveClick}
                    >
                        {saving ? 'Saving...' : 'Save'}
            </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="mt-5">
                    <BannerEditForm
                        data={data}
                        btnRef={btnRef}
                        resetbtnRef={resetbtnRef}
                        save={save}
                        type={type}
                    >
                    </BannerEditForm>
                </div>
            </CardBody>
        </Card>
    )
};


export const AddBanners = injectIntl(connect(null, toaster.actions)(_AddBanners))