/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
// import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function UserProfileDropdown() {

  
  return (
    <div className="topbar-item">
      <div
        className={
          "btn btn-icon btn-clean btn-lg btn-dropdown mr-1"
        }
      >
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="logou-btn-tooltip">Logout</Tooltip>}
        >
          <Link
            to="/logout"
            className="btn font-weight-bold"
          >
            <span className="svg-icon svg-icon-md">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")} />
            </span>
          </Link>
        </OverlayTrigger>
      </div>
    </div>
  );
}
