import { format } from 'date-fns';
import React from 'react';

export default class PrintOrderDetail extends React.Component {
   constructor(props) {
      super(props);
   }
   render() {
      const createdAt = new Date(this.props.data.created_at);
      const dateAndTime = format(createdAt, 'PPpp');

      return (
         <>
            <div className='csm-m0'>
               <div class='print-head'>
                  <div class='csm-jcc csm-df csm-pos-rel'>
                     <div class='article_header'>
                        <h2 className='test'>Purple Peedika</h2>
                        <div className='csm-df'>
                           <h4>
                              42/2917/A , Arakkakadavu Road Vennala P.O , Ernakulam Kerala Pin :
                              682028
                           </h4>
                        </div>
                     </div>
                     <div class='article_route'>
                        <div class='circle'>
                           <span>R{this.props.data.route_id}</span>
                        </div>
                     </div>
                  </div>
                  <br></br>
                  <h2>Order Details</h2>
               </div>
               <hr class='new2'></hr>
               <div class='csm-df csm-jcc'>
                  <div class='article'>
                     <h5>Order ID - {this.props.data.id} </h5>
                     <h5>Date - {dateAndTime} </h5>
                     <h5>Customer Name - {this.props.data.name}</h5>
                     <h5>Adress Line 1 - {this.props.data.address_line_1}</h5>
                     <h5>City - {this.props.data.city}</h5>
                     <h5>ZipCode - {this.props.data.post_code}</h5>
                  </div>

                  <div class='article csm-ml-50'>
                     <h5> No of Items - {this.props.data.total_quantity}</h5>
                     <h5>Billed Type - {this.props.data.transaction.payment_method} </h5>
                     <h5> Mobile number - {this.props.data.mobile}</h5>
                     <h5>Adress Line 2 - {this.props.data.address_line_2}</h5>
                     <h5>State - {this.props.data.state}</h5>
                     <h5>Country - {this.props.data.country}</h5>
                  </div>
               </div>
               <hr class='new2'></hr>

               <div class='csm-grid csm-gtc-3 csm-jic csm-81p csm-ml-auto'>
                  <div class='article'>
                     <h5>Product Name</h5>
                  </div>
                  <div class='article'>
                     <h5>Quantity</h5>
                  </div>
                  <div class='article'>
                     <h5>Amount </h5>
                  </div>
               </div>
               <hr class='new2'></hr>
               <div class='csm-grid csm-gtc-3 csm-jic csm-81p csm-ml-auto'>
                  <div class='article'>
                     {this.props.data.order_items.map((product, index) => (
                        <h5>
                           {product.product.name}
                           <br></br>
                        </h5>
                     ))}
                     <br></br>
                     <br></br>
                     <h3>Total</h3>
                  </div>

                  <div class='article'>
                     {this.props.data.order_items.map((product, index) => (
                        <h5>
                           {product.qty} {product.product.qty_label}
                           <br></br>
                        </h5>
                     ))}
                  </div>

                  <div class='article'>
                     <h5> </h5>
                     {this.props.data.order_items.map((product, index) => {
                        let sum = product.qty * product.mrp_per_unit;
                        return (
                           <h5>
                              {Number(sum).toFixed(2)} <br></br>
                           </h5>
                        );
                     })}
                     <br></br>
                     {/* <h5>{this.props.data.meta.sub_total}</h5> */}
                     <br></br>
                     <h3>{this.props.data.total_mrp}</h3>
                  </div>
               </div>
               <hr class='new2'></hr>

               <div class='csm-grid csm-gtc-3 csm-jic csm-81p csm-ml-auto'>
                  <div class='article'>
                     <h5>Packing Charge</h5>
                     <h5>Delivery Charge</h5>
                     <h5>Discount From Purple Points</h5>
                  </div>

                  <div className='csm-hidden'>
                     <h5>Delivery Charge</h5>
                  </div>

                  <div class='article'>
                     <h5>{this.props.data.meta.packing_charge}</h5>
                     <h5>{this.props.data.meta.delivery_charge}</h5>
                     <h5>{this.props.data.meta.discount_from_purple_points}</h5>
                  </div>
               </div>

               <hr class='new2'></hr>
               <div class='csm-grid csm-gtc-3 csm-jic csm-81p csm-ml-auto'>
                  <div class='article'>
                     <h4 class='print-font'>Net Amount : </h4>
                  </div>

                  <div class='article csm-hidden'>
                     <h3>{this.props.data.amount_to_be_paid}</h3>
                  </div>

                  <div class='article'>
                     <h3>{this.props.data.amount_to_be_paid}</h3>
                  </div>
               </div>
               <hr class='new2'></hr>

               <div className='csm-df csm-jcc csm-aic csm-mt-30'>
                  <h3>MARKET CART Pvt Ltd</h3>
               </div>

               <div className='csm-m0'></div>
            </div>
         </>
      );
   }
}
