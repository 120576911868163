import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Users } from "../../utlis/API";
import Cards from "./View/Cards"
import List from "./View/Table"
import PontModal from "./View/PointModal"
import { useDispatch } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux"

export function Add (props) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [id, setId] = useState()
    const back = () => props.history.push({pathname : '/customers/list'})

    useEffect(() => {
        const id = props.match.params.id
        setId(id)
        if (id !== undefined) fetchData(id)
    }, [])

    
  const fetchData = (id) => {
    Users.getById(id).then((response) => {
        setData(response)
    }).catch(e => {
      props.openToaster({ open: true, message: 'Failed to get User', type: 'error' })
    })
  }

  const AddPoints = (values) => {
    Users.AddRewardPoints(values, id).then((response) => {
      fetchData(id)
      dispatch(toaster.actions.openToaster({ open: true, message: response.message, type: 'success' }))
    }).catch(e => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to add purple coins', type: 'error' }))
    })
  }
    
    return (
      <>
        <Card>
          <CardHeader title="Users View">
            <CardHeaderToolbar>
              <button
                type="button"
                onClick={back}
                className="btn btn-light"
              >
                <i className="fa fa-arrow-left"></i>
                  Back
                </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
              {data && 
                <>
                <Cards
                data = {data}
                 />
                 </>
              }
          </CardBody>
        </Card>
        
        <Card>
          <CardHeader title="">
            <CardHeaderToolbar>
              <PontModal
                 AddPoints={AddPoints}
                 />
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
              {data && 
                <>
                 <List 
                    data = {data}
                 />
                 </>
              }
          </CardBody>
        </Card>
        </>
    );
}