// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";
import { styles } from "../categories/catStyles"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";
import { fileToBase64 } from "../../utlis/CommonMethods";
import { PrevElement } from "../../../_metronic/layout/components/extras/ImagePreview";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Validation schema
const DriverEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),
     email: Yup.string()
        .email("Wrong email format")
        .required("Email is required"),
    mobile: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("Phone number is required")
        .min(10, "Phone number is not valid"),
});

export function DriverEditForm({
    data,
    btnRef,
    resetbtnRef,
    save,
    type
}) {
    const [imagePrev, setImagePrev] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [imageTouched, setImageTouched] = useState(false)
    const imageUpload = React.createRef();
    
    useEffect(() => {
        setImagePrev(data.licence_copy)
    }, [data])

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={DriverEditSchema}
                onSubmit={(values) => {
                    console.log("values",values)
                    save(values);
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, values }) => (
                    <>
                    <Form className="form form-label-right">
                            <div className="row image-uploader-container">
                                <div className="col-lg-12">
                                    <div className="form-group row col-lg-8 p-0">
                                        <div className="col-lg-12">
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="Name"
                                                label="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row col-lg-8 p-0">
                                        <div className="col-lg-12">
                                            <Field
                                                name="email"
                                                component={Input}
                                                placeholder="Email"
                                                label="Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row col-lg-8 p-0">
                                        <div className="col-lg-12">
                                            <Field
                                                name="mobile"
                                                component={Input}
                                                placeholder="Mobile Number"
                                                label="Mobile Number"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        style={{ display: "none" }}
                                        ref={btnRef}
                                        onClick={() => {
                                            setImageTouched(true)
                                            handleSubmit()
                                        }}
                                    ></button>
                                    <button
                                        type="button"
                                        style={{ display: "none" }}
                                        ref={resetbtnRef}
                                        onClick={() => {
                                            console.log('reset btn')
                                            if (type != 'edit') setImagePrev(null)
                                            setFieldValue("name", "");
                                            setFieldValue("email", "");
                                            setFieldValue("mobile", "");
                                            // resetForm()
                                            imageUpload.current.value = null
                                        }}
                                    ></button>
                                </div>
                                <div className="col-lg-4 image-wrapper">
                                    <div className="col-lg-12 category-image-upload" >
                                        <div className="row m-b-1">
                                            <div className={'form-group image-upload image-upload-height' + (errors["image"] && imageTouched ? 'error' : '')} title="Upload image" >
                                                {imagePrev === null || imagePrev === '' ? (
                                                    <>
                                                        <SVG src={toAbsoluteUrl("/media/svg/custom/cloud-upload.svg")} onClick={() => {
                                                            imageUpload.current.click()
                                                            setImageTouched(true)
                                                        }} />
                                                        <p onClick={() => {
                                                            imageUpload.current.click()
                                                            setImageTouched(true)
                                                        }}>Click to upload the image</p>
                                                    </>) : (
                                                        <PrevElement
                                                            image={imagePrev}
                                                            change={() => imageUpload.current.click()}
                                                            cancel={() => {
                                                                setFieldValue("licence_copy", null)
                                                                imageUpload.current.value = null
                                                                setImagePrev(null)
                                                            }}
                                                        />
                                                    )
                                                }
                                                <input ref={imageUpload} onChange={async (event) => {
                                                    console.log(event.currentTarget.files[0])
                                                    if (!event.currentTarget.files[0]) return
                                                    setFieldValue("licence_copy", event.currentTarget.files[0]);
                                                    let image = await fileToBase64(event.currentTarget.files[0])
                                                    setImagePrev(image)
                                                }} style={{ display: "none" }} name="licence_copy" type="file" accept="image/*" />
                                            </div>
                                            {errors["image"] && imageTouched ? <span className="image-error-span">Image is required</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
