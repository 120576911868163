import { Formik } from 'formik';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as toaster from "../../redux/reducer-extras/toasterRedux";
import { AutoComplete } from '../../_metronic/_partials/controls/forms/AutoComplete';

const CustomFilterComponent = (props) => {
    const [filterType, setFilterType] = useState(null)
    const [filterValue, setFilterValue] = useState(null)

    const [tmpFilterType, setTmpFilterType] = useState({})
    const [tmpFilterValue, setTmpFilterValue] = useState('')

    const dispatch = useDispatch()

    const applyFilter = () => {
        if (props.isloading) return

        if(props.setIsInFilterMode){
            // for users page, useEffect not firing on filter mode
            props.setIsInFilterMode(true);
        }

        if (filterType == null) {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Select filter Type.', type: 'error' }))
            return
        }

        if (filterValue == null) {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Enter filter value.', type: 'error' }))
            return
        }
        
        props.filterChanged({filterType, filterValue })
    }

    const clearFilter = () => {
        if(props.setIsInFilterMode){
            // for users page, useEffect not firing on filter mode
            props.setIsInFilterMode(false);
        }

        setFilterType(null)
        setFilterValue('')
        setTmpFilterType({})
        setTmpFilterValue('')
        props.filterChanged({ filterType: null, filterValue: null })
    }

    return (
        <>
            <Formik>
                {({ handleSubmit, resetForm, setFieldValue, errors, values }) => (
                    <>

                        <div className="dispatch-filter-row">
                            <div className="coloums first">
                                <AutoComplete
                                    value={tmpFilterType}
                                    label="Filter Type"
                                    name="route"
                                    options={props.searchType}
                                    onChange={(value) => setFilterType(value)}
                                />
                            </div>

                            <div className="coloums">
                                <label>Filter Value</label>
                                <input
                                    value={tmpFilterValue}
                                    className='form-control'
                                    onChange={(e) => {
                                        setTmpFilterValue(e.target.value);
                                        setFilterValue(e.target.value)
                                     }
                                    }
                                    placeholder='Enter filter value'
                                />

                            </div>

                            <div className="btn-ctnr">
                                <button
                                    type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={applyFilter}
                                >
                                    Apply filter
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary-light ml-2"
                                    onClick={clearFilter}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default  CustomFilterComponent;