import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";

import {
    sortCaret,
} from "../../../_metronic/_helpers"
import {
    Card,
    CardBody,
    CardHeader,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CommonTable } from "../page-components/CommonTable";
import { Users } from "../../utlis/API"
import * as alert from "../../../redux/reducer-extras/alertRedux"
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import { useDispatch } from "react-redux";
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import { CardHeaderToolbar } from './../../../_metronic/_partials/controls/Card';
import CustomFilterComponent from "../../custom/CustomFilterComponent";

export function CustomersListTable(props) {
    const dispatch = useDispatch()
    const suhbeader = useSubheader();
    suhbeader.setTitle("Users");

    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "10", value: 10 },
            { text: "20", value: 20 },
            { text: "30", value: 30 }
        ],
        sizePerPage: 30,
        page: 1,
    };
    const [init, ] = useState(true);
    const [users, setUsers] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);
    const [totalSize, setTotalSize] = useState(10)
    const [loading, setLoading] = useState(true)
    const [isInFilterMode, setIsInFilterMode] = useState(false);
    
    const [isloading, setIsloading] = useState(false)
    const [searchType, setSearchType] = useState([
        {
            label:'Name',
            value:'name'
        },
        {
            label:'Mobile',
            value:'mobile'
        },
    ])

    useEffect(() => {
        (()=>{
            if(!isInFilterMode){
                fetchData()
            }
        })()
    }, [_paginationOptions, isInFilterMode])

    useEffect(() => {
        (() => {
            fetchData('init')
        })()
    }, [init])

    const fetchData = (type = undefined, search_key = null, search_value = null) => {
        setLoading(true)
        Users.get(_paginationOptions.page, _paginationOptions.sizePerPage, search_key, search_value).then((response) => {
            try {
                setUsers([...response.users])
                const totalSize = response.total_pages * _paginationOptions.sizePerPage
                setTotalSize(totalSize)
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
        })
    }
    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        let m = { ..._paginationOptions }
        setPaginationOptions(m)
    }

    const actionBtns = (cellContent, row, rowIndex) => {
        console.log(row)
        return (
            <>
                {(row.is_approved === 0 && (
                    <OverlayTrigger
                        overlay={<Tooltip id="products-edit-tooltip">Approve</Tooltip>}
                    >
                        <a
                            href=""
                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                            onClick={() => approveRejectUser(row.id, true)}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Double-check.svg")}
                                />
                            </span>
                        </a>
                    </OverlayTrigger>
                ))}
                {(row.is_approved === 1 && (
                    <OverlayTrigger
                        overlay={<Tooltip id="products-delete-tooltip">Waiting</Tooltip>}
                    >
                        <a
                            href=""
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => approveRejectUser(row.id, false)}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                            </span>
                        </a>
                    </OverlayTrigger>
                ))}

            </>
        )
    }
    const userAvatars = (cellContent, row, rowIndex) => {
        let profile = row.profile_picture
        if (profile === "") profile = "/media/users/default.jpg"
        return (
            <div className="d-flex align-items-center table-avatar-container">
                <div className="symbol symbol-100 mr-5">
                    <div className="symbol-label" >
                        {/* <img src={profile} alt={row.name.slice(0,5)} /> */}
                        <img src={profile} alt={''} />
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <a className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                        {row.name}
                    </a>
                    <div className="navi">
                        <a className="navi-item">
                            <span className="navi-link p-0 pb-2">
                                <span className="navi-text text-muted text-hover-primary">
                                    {row.email}
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
    const columns = [
        {
            dataField: "id",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            formatter: userAvatars
        },
        {
            dataField: "mobile",
            text: "Mobile",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{cellContent}</span>)
            }
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{cellContent}</span>)
            }
        },
        // {
        //     dataField: "user_type_id",
        //     text: "User type",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     formatter: (cellContent, row, rowIndex) => {
        //         return (<span>{row.user_type.name}</span>)
        //     }
        // },
        // {
        //     dataField: "is_approved",
        //     text: "Status",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     formatter: (cellContent, row, rowIndex) => {
        //         return (
        //             <span className={'user-status ' + (cellContent == "1" ? 'approve' : 'reject')}>
        //                 {cellContent === "1" ? 'Approved' : 'Waiting'}
        //             </span>
        //         )
        //     }
        // },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                view: true,
                edit: false,
                delete: false,
                print: false,
                viewItem: (id) => { 
                    props.history.push(`/customers/${id}/edit`)
                 },
            },
        }
    ];

    const approveRejectUser = (id, status) => {
        let msg = status ? 'Approve this user' : 'change the user status to waiting'
        dispatch(alert.actions.showAlert({
            open: true,
            message: `Are you sure you want to ${msg.toLocaleLowerCase()}`,
            type: 'confirm',
            title: status ? 'Approve user' : 'Update user status',
            cb: (s) => {
                if (s) _proceedapproveRejectUser(id, status, msg)
            }
        }))
    }
    const _proceedapproveRejectUser = (id, status, msg) =>{
        console.log(status)
        const data = {
            "is_approved": status
        } 
        Users.patch(data, id).then((response)=>{
            console.log(response)
            // let user = users.find(e=> e.id == id)
            // if(user) {
            //     user.is_approved = status ? 1 : 0
            //     setUsers([...users])
            // }
            fetchData()
            dispatch(toaster.actions.openToaster({ open: true, message: 'User successfully ' + msg.toLocaleLowerCase(), type: 'success' }))
        }).catch(err=>{
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to update user', type: 'error' }))
        })
    }   


    const filterChanged = (filter) => {
        if (filter.filterType == null && filter.filterValue == null) {
            setIsInFilterMode(false);

            setUsers([]);
            paginationOptions.totalSize = 0
            setPaginationOptions(paginationOptions)
            return fetchData();
        }

        setIsloading(true)
        setLoading(true)

        Users.get(_paginationOptions.page, _paginationOptions.sizePerPage, filter.filterType, filter.filterValue).then((response) => {
            try {
                let orders = response.users.slice(0, paginationOptions.sizePerPage)
                setUsers(orders);
                const _totalSize = response.total_pages;
                setTotalSize(_totalSize)
            }
            catch (e) {
                console.log(e)
            }
            setIsloading(false)
            setLoading(false)
        }).catch(err => {
            setIsInFilterMode(false);
            
            setIsloading(false)
            setLoading(false)
            props.openToaster({ open: true, message: 'Failed to fetch users', type: 'error' })
        })
    }


    return (
        <>
            <Card>
                {loading && (<ModalProgressBar />)}

                <CardHeader title="Users">
                        <CustomFilterComponent
                            searchType={searchType}
                            isloading={isloading}
                            setIsInFilterMode={setIsInFilterMode}
                            filterChanged={filterChanged} // filterChanged only in users
                        />
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>

                <CardBody>
                    <CommonTable
                        entities={users}
                        onChange={onTableChanged}
                        paginationOptions={_paginationOptions}
                        columns={columns}
                        listLoading={loading}
                        totalSize={totalSize}
                    />
                </CardBody>
            </Card>
        </>
    );
}