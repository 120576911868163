import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { CustomersListTable } from "./CustomersTable";
import { Add } from "./Add";

export const Customers = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Users");
  return (
      <Switch>
          <Redirect
              exact={true}
              from="/customers"
              to="/customers/list"
          />
          {/* <ContentRoute from="/customers/add" component={AddProducts} /> */}
          <ContentRoute from="/customers/list" component={CustomersListTable} />
          <ContentRoute from="/customers/:id/edit" component={Add} />
      </Switch>
  );
};
