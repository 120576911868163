import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { Categories } from "./pages/categories";
import { MerchantStores } from "./pages/stores";
import { UserTypes } from "./pages/user-types";
import { DashboardPage } from "./pages/DashboardPage";
import { Products } from "./pages/products";
import { Toaster } from "../_metronic/layout/components/extras/Toaster"
import * as toaster from "../redux/reducer-extras/toasterRedux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { AlertDialoges } from "../_metronic/layout/components/extras/alerts/alert";
import { Banners } from "./pages/Banners/Banners";
import { Orders } from "./pages/orders/Orders";
import { Customers } from "./pages/customers";
import { Reports } from "./pages/reports/Report";
import { TimeSlots } from "./pages/time-slots/TimeSlots";
import { Dispatches } from "./pages/dispatches/Dispatches";
import { Targets } from "./pages/targets/Targets";
import { Coupons } from "./pages/coupons/Coupons";
import { Drivers } from "./pages/drivers/Drivers";
import { Deals } from "./pages/deals/Deals";


function BasePage({ closeToaster }) {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />
                <ContentRoute path="/stores" component={MerchantStores} />
                <ContentRoute path="/products" component={Products} />
                <ContentRoute path="/categories" component={Categories} />
                <ContentRoute path="/targets" component={Targets} />
                <ContentRoute path="/customers" component={Customers} />
                <ContentRoute path="/user-types" component={UserTypes} />
                <ContentRoute path="/banners" component={Banners} />
                <ContentRoute path="/orders" component={Orders} />
                <ContentRoute path="/reports" component={Reports} />
                <ContentRoute path="/time-slots" component={TimeSlots} />
                <ContentRoute path="/dispatches" component={Dispatches} />
                <ContentRoute path="/coupons" component={Coupons} />
                <ContentRoute path="/drivers" component={Drivers} />
                <ContentRoute path="/deals" component={Deals} />
                <Redirect to="error/error-v1" />
            </Switch>
            <Toaster></Toaster>
            <AlertDialoges/>
        </Suspense>
    );
}
export default injectIntl(connect(null, toaster.actions)(BasePage))
