import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Targets, Store } from "../../utlis/API"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import * as alert from "../../../redux/reducer-extras/alertRedux";
import Select from 'react-select'

const _ListTargets = (props) => {
    const dispatch = useDispatch()
    const paginationOptions = {
        custom: true,
        totalSize: 0,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 }
        ],
        sizePerPage: 5,
        page: 1,
    };
    const columns = [
        {
            dataField: "id",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "target_amount",
            text: "Amount",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "target_type",
            text: "Type",
            sort: true,
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <span style={{
                        background: cellContent != 'month' ? '#64cd72' : '#cd6a64',
                        padding: "2px 5px",
                        color: "#fff",
                        textTransform: 'capitalize',
                        borderRadius: "5px"
                    }}>{cellContent}</span>
                )
            },
            sortCaret: sortCaret,
        },
        {
            dataField: "target_period",
            text: "Period",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                delete: false,
                openEditProductPage: (id) => { props.history.push(`/targets/add${props.location.search}`) },
                // openDeleteProductDialog: (id) => { deleteItem(id) },
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    const [store, setStore] = useState({});
    const [stores, setStores] = useState({});
    const [init, setInit] = useState(true);
    const [targets, setTargets] = useState([]);
    const [tempTargets, setTempTargets] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        onTableChanged('page', _paginationOptions)
    }, [tempTargets])
    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        setLoading(true)
        Store.get().then(response => {
            const stores = response.stores.map((e) => ({ label: e.name, value: e.id }))
            setStores([...stores])
            setLoading(false)
            if (query.get('storeId') !== null || query.get('storeId') != undefined) {
                let val = query.get('storeId')
                let store = stores.find((e) => e.value == val)
                fetChData(store.value)
                setStore(store)
            }

        }).catch(e => {
            console.log(e)
            setLoading(false)
        })
    }, [init])
    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        let start = (pageNumber - 1) * sizePerPage
        let targets = tempTargets.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        setTargets(targets)
        setPaginationOptions(_paginationOptions)
    }
    const storeChanged = (value) => {
        let store = stores.find((e) => e.value == value.value)
        fetChData(store.value)
        setStore(store)
    }
    const fetChData = (id) => {
        props.history.push(`/targets/list?storeId=${id}`)
        setLoading(true)
        Targets.get(id).then((response) => {
            try {
                console.log(response)
                setTempTargets([...response.target])
                let targets = response.target.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.target.length
                setTargets(targets)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
        })
    }
    // const deleteItem = (id) => {
    //     dispatch(alert.actions.showAlert({
    //         open: true,
    //         message: 'Are you sure you want to delete this banner',
    //         type: 'confirm',
    //         title: 'Delete product',
    //         cb: (status) => {
    //             if (status) proceedDelete(id)
    //         }
    //     }))
    // }
    // const proceedDelete = (id)=>{
    //     Banners.delete(id).then((res) => {
    //         let _tmpBanners = [...tempBanners]
    //         let index = _tmpBanners.findIndex((e) => e.id == id)
    //         if (index != -1) _tmpBanners.splice(index, 1)
    //         setTempBanners([..._tmpBanners])
    //         dispatch(toaster.actions.openToaster({ open: true, message: 'Banner successfully deleted', type: 'success' }))
    //     }).catch(e => {
    //         dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete banner', type: 'error' }))
    //     })
    // }
    return (
        <Card>
            {loading && (<ModalProgressBar />)}
            <CardHeader title={store.value == undefined ? 'Select a store' : store.label}>
                <CardHeaderToolbar>
                    <div style={{ width: '300px', marginRight: '10px' }}>
                        <Select
                            value={store}
                            options={stores}
                            onChange={storeChanged}></Select>
                    </div>

                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => props.history.push('/targets/add' + props.location.search)}
                    >
                        Add new target
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <CommonTable
                    entities={targets}
                    onChange={onTableChanged}
                    paginationOptions={_paginationOptions}
                    columns={columns}
                    listLoading={loading}
                />
            </CardBody>
        </Card>
    )
};
export const ListTargets = injectIntl(connect(null, toaster.actions)(_ListTargets))

