import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { DriverEditForm } from "./DriverEditForm";
import { Driver } from "../../utlis/API";
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import { useDispatch } from "react-redux";

const initialData = {
  name: '',
  email: '',
  mobile: '',
  licence_copy: '',
}

export const AddDrivers = (props) => {
  const dispatch = useDispatch()
  const [init] = useState(true);
  const [data, setData] = useState(initialData);
  const [type, setType] = useState('add')
  const [driverId, setId] = useState(null)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const backToDriversList = () => props.history.goBack()
  const saveDriver = (values) => {
    //   console.log("values",values, typeof values.licence_copy)
      let formdata = new FormData()
      formdata.append('name', values.name)
      formdata.append('email', values.email)
      formdata.append('mobile', values.mobile)
      if (typeof values.licence_copy !== "string" && values.licence_copy !== null && values.licence_copy !== 0) formdata.append('licence_copy', values.licence_copy)
    if (type == 'edit') formdata.append('_method', 'PUT')
    setSaving(true)
    Driver.save(formdata, driverId).then(response => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Driver successfully saved', type: 'success' }))
      if (type === 'add') resetbtnRef.current.click()
      else backToDriversList()
      setSaving(false)
    }).catch(err => {
      err = JSON.parse(err.message)
      let msg = loopErros(err.data.errors)
      if (msg == null) msg = 'Failed to save driver'
      dispatch(toaster.actions.openToaster({ open: true, message: msg, type: 'error' }))
      setSaving(false)
    })
  }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveDriverClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  useEffect(() => {
    reInitializeComponent() 
  }, [init]);

  const reInitializeComponent = async () => { 
    try {
      let id = props.match.params.id
      if (id !== undefined) fetchData(id)
    }
    catch (e) {
      console.log(e)
    }
  }

  const fetchData = (id) => {
    Driver.getById(id).then((r) => {
      setId(id)
      const data = {
        name: r.driverDetail.name,
        email: r.driverDetail.email,
        mobile: r.driverDetail.mobile,
        licence_copy: r.driverDetail.driver.licence_copy,
      }
      setType('edit')
      setData({ ...data })
    }).catch(e => {
      // dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to get Store', type: 'error' })
    })
  }


  const loopErros = (data) => {
    let keys = Object.keys(data)
    if (keys.length > 0) {
      if (Array.isArray(data[keys[0]])) {
        let message = data[keys[0]][0]
        if (message !== undefined) return message
      }
    }
    else return null
  }
  return (
    <Card>
      <CardHeader title="Add drivers">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToDriversList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            disabled={saving}
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveDriverClick}
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <DriverEditForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            save={saveDriver}
          >
          </DriverEditForm>
        </div>

      </CardBody>
    </Card>
  )
};
