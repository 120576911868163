import React from "react";

export const PrevElement = ({ image, cancel, change, disableActions }) => {
    const onError = function (event) {
        event.target.src ="/media/images/image-gallery.png"
    }
    return (
        <div className="image-upload-prev">
            <img src={image} onError={onError} alt={image}/>
            { disableActions ? null : (
                <div className="image-upload-prev-footer">
                    <button type="button" title="cancel image" onClick={cancel}>Cancel</button>
                    <button type="button" title="change image" onClick={change}>Change</button>
                </div>
            )
            }
        </div>
    )
}