// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from "../../../../_metronic/_partials/controls";


// Validation schema
const ProductEditSchema = Yup.object().shape({
    points: Yup.string()
        .required("Points is required")
        .matches(/^[0-9]*$/, "Points must be an integer"),
    log_message : Yup.string()
        .required("Message is required")
});

export function EditForm({
    btnRef,
    AddPoints,
    handleClose
}) {

    const initialValues = {
        points : '',
        log_message : '',
        action_type : 'credit'
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={ProductEditSchema}
                onSubmit={(values) => {
                    handleClose()
                    AddPoints(values)
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, touched, ...others }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="row image-uploader-container">
                                <div className="col-lg-12">
                                    <div className="form-group row col-lg-10 p-0">
                                        <div className="col-lg-12">
                                            <Field
                                                name="points"
                                                component={Input}
                                                placeholder="Points"
                                                label="Points *"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row col-lg-10 p-0">
                                        <div className="col-lg-12">
                                            <Field
                                                name="log_message"
                                                component={TextArea}
                                                placeholder="Message"
                                                label="Message *"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        style={{ display: "none" }}
                                        ref={btnRef}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    ></button>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
