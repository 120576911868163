/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";

export function ListsWidget1({ className, stores }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Top Nattukada Stores
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          {stores.map((store, index) => {
            return (
              <div className="d-flex align-items-center mb-10" key={'topstore-'+ index}>
                <div className="symbol symbol-40 symbol-light-primary mr-5">
                  <span className="symbol-label">
                    <span className="svg-icon svg-icon-lg svg-icon-primary">
                      {store.id}
                      {/* <SVG
                        className="h-75 align-self-end"
                        // src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                        src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}
                      ></SVG> */}
                    </span>
                  </span>
                </div>
                <div className="d-flex flex-column font-weight-bold">
                  <span className="text-dark text-hover-primary mb-1 font-size-lg">
                    {store.name}
                  </span>
                  {/* <span className="text-muted">Project Manager</span> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}
