import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import {  ListDeals, 
    AddProductToDeal,
    ListDealProducts
 } from "./index"
export const Deals = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Deal Management");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/deals"
        to="/deals/list"
      />
      <ContentRoute from="/deals/:id/product/add" component={AddProductToDeal} />
      <ContentRoute from="/deals/:id/product/:id/edit" component={AddProductToDeal} />
      <ContentRoute from="/deals/list" component={ListDeals} />
      <ContentRoute from="/deals/:id/products/list" component={ListDealProducts} />
    </Switch>
  );
};
