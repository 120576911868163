// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import GoogleMap from 'google-map-react';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SearchBox } from "./SearchBox"
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const phoneRegExp = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/


// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Store name is required"),
    code: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Code name is required"),
    gstNumber: Yup.string()
        .min(15, "Minimum 15 digits")
        .max(15, "Maximum 15 digits")
        .required("GST Number is required"),
    userName: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("user name is required"),
    userEmail: Yup.string()
        .email("Wrong email format")
        .required("Name is required"),
    userMobile: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("Phone number is required")
        .min(10, "Phone number is not valid"),
    description: Yup.string().required("Color is required")
});
const Marker = ({ text }) => {
    return (<SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} onClick={() => { }} />)
};
const apiKey = "AIzaSyBsWkQFTNgXLgEtKGV0iaAQ5B_Ui0MbviA" //logidots
const mapOpt = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    zoom: 11
};
export function StoreEditForm({
    data,
    position,
    btnRef,
    resetbtnRef,
    save,
    handlemapClick
}) {
    const [mapsApiLoaded, setApiLoaded] = useState(false)
    const [mapInstance, setMapInstance] = useState({})
    const [mapsapi, setMapsapi] = useState({})
    const apiLoaded = (map, maps) => {
        setApiLoaded(true)
        setMapInstance(map)
        setMapsapi(maps)
    }
    const [mapOptions, setMapOptions] = useState(mapOpt)
    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    save(values);
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue }) => (
                    <>

                        <Form className="form form-label-right">
                            <div className="row">
                                <div className="col-lg-4 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder="Store name"
                                            label="Store Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="code"
                                            component={Input}
                                            placeholder="Store code"
                                            label="Store code"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="userName"
                                            component={Input}
                                            placeholder="User name"
                                            label="User name"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="gstNumber"
                                            component={Input}
                                            placeholder="GST Number"
                                            label="GST Number"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="userEmail"
                                            component={Input}
                                            placeholder="Email"
                                            label="Email"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="userMobile"
                                            component={Input}
                                            placeholder="Mobile No."
                                            label="Mobile No."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 px-md-5">
                                    <div className="form-group row">
                                        <label>Address</label>
                                        <Field
                                            name="address"
                                            as="textarea"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 px-md-5">
                                    <div className="form-group row">
                                        <label>Description</label>
                                        <Field
                                            name="description"
                                            as="textarea"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => {
                                    setFieldValue('name', '')
                                    setFieldValue('description', '')
                                    setFieldValue('userName', '')
                                    setFieldValue('userEmail', '')
                                    setFieldValue('userMobile', '')
                                }}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
            <label>Select location</label>
            {/* <SearchBox placeholder="Search..." onPlacesChanged={(e) => console.log(e)}></SearchBox> */}
            {mapsApiLoaded && <SearchBox map={mapInstance} mapsapi={mapsapi} onPlacesChanged={handlemapClick} />}
            <div style={{ height: '300px', width: '100%', marginBottom: "15px" }}>
                <GoogleMap
                    bootstrapURLKeys={{
                        key: apiKey,
                        libraries: ['places']
                    }}
                    center={position}
                    zoom={mapOptions.zoom}
                    onClick={handlemapClick}
                    onGoogleApiLoaded={({ map, maps }) => {
                        apiLoaded(map, maps);
                    }}
                    libraries
                >
                    <Marker
                        lat={position.lat}
                        lng={position.lng}
                        text=""
                    />
                </GoogleMap>
            </div>
        </>
    );
}
