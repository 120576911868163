// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect, useRef, } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, DatePickerField } from "../../../_metronic/_partials/controls";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import { Multiselect } from 'multiselect-react-dropdown';



// Validation schema
const CouponEditSchema = Yup.object().shape({
    coupon_code: Yup.string()
        .required("Coupon code is required"),
    discount_type: Yup.string()
        .required("Discount type is required"),
    minimum_cart_amount: Yup.string()
        .required("Minimum cart amount is required"),
    minimum_discount_allowed: Yup.string()
        .required("Minimum discount allowed is required"),
    // products_applicable: Yup.string()
    //     .required("Products applicale is required"),
    usage_limit: Yup.string().required("Usage limit is required"),
    valid_untill : Yup.string().nullable(),
});
const apiKey = "AIzaSyBsWkQFTNgXLgEtKGV0iaAQ5B_Ui0MbviA" //logidots

export function CouponEditForm({
    products,
    btnRef,
    resetbtnRef,
    save,
    discount_types,
    data,
    saveClick
}) {
    const [init] = useState(true);
    const [selectedDiscountType, setSelectedDiscountType] = useState({});
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [validUntil, setValidUntil] = useState(null)

    useEffect(() => {
        let unmounted = false;
        if (data.valid_untill != "" && data.valid_untill != undefined) setValidUntil(new Date(data.valid_untill))
        if (data.discount_type != null && data.discount_type != "") {
            let value =  discount_types.find((e) => e.value === data.discount_type)
            if (value) setSelectedDiscountType(value)
        }
        if (products && data.products_applicable != null && data.products_applicable != "") {
            let productsArray = [];
            data.products_applicable.forEach((selcted_product) => {
                 products.forEach((product) => {
                    if(selcted_product == product.id){
                        productsArray.push(product)
                    }
                    
                })
            });
            if (productsArray.length > 0) setSelectedProducts(productsArray)
        }
        return () => { unmounted = true };
    }, 
    [data, products])

    const onSelect = (selectedList, selectedItem) => {
       setSelectedProducts(selectedList)
    }
    
    const onRemove = (selectedList, removedItem) => {
        setSelectedProducts(selectedList)
    }

    const handleFromDateChange = (ev) => {
        setValidUntil(ev);
    };
    const ref = useRef(null);
    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CouponEditSchema}
                onSubmit={(values) => {
                    let productsArr = selectedProducts.map(({id}) => {return id })
                    var date= moment(validUntil).format('YYYY-MM-DD HH:MM:SS');
                    values.valid_untill = date;
                    values.products_applicable = productsArr;
                    save(values);
                }}
                innerRef={ref}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, touched }) => (
                    <>

                        <Form className="form form-label-right">
                            <div className="row">
                                <div className="col-lg-6 px-md-5">
                                    <div className="form-group row">
                                        <Field
                                            name="coupon_code"
                                            component={Input}
                                            placeholder="Coupon code"
                                            label="Coupon code"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-md-5">
                                    <AutoComplete
                                    multiple={true}
                                        value={selectedDiscountType}
                                        label="Discount type"
                                        name="discount_type"
                                        options={discount_types}
                                        // onChange={(value) => setSelectedDiscountType(value)}
                                        onChange={(value) => setFieldValue("discount_type", value)} 
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 px-md-0">
                                    {/* <div className="form-group row"> */}
                                    <label>Select Products applicable</label>
                                    <Multiselect
                                        options={products} 
                                        name="products_applicable"
                                        placeholder="Products applicable"
                                        hidePlaceholder = {selectedProducts.length == 0 ? false : true}
                                        selectedValues={selectedProducts}
                                        onSelect={onSelect} 
                                        onRemove={onRemove} 
                                        displayValue="name"
                                        />
                                         {saveClick && selectedProducts.length == 0 ? <span className="image-error-span">Products applicable is required</span> : ''}

                                    {/* <AutoComplete
                                        value={selectedProducts}
                                        label="Products applicable"
                                        name="products_applicable"
                                        options={products}
                                        onChange={(value) => { console.log("value",value);setFieldValue("products_applicable", value)}} /> */}
                                    {/* </div> */}
                                </div>
                                <div className="col-lg-6 px-md-10">
                                    <div className="form-group row">
                                    <Field
                                            name="usage_limit"
                                            component={Input}
                                            placeholder="Usage limit"
                                            label="Usage limit"
                                            type="number"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 px-md-5">
                                    <div className="form-group row">
                                    <Field
                                        name="minimum_cart_amount"
                                        component={Input}
                                        placeholder="Minimum cart amount"
                                        label="Minimum cart amount"
                                        type="number"
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-6 px-md-10">
                                    <div className="form-group row">
                                        <Field
                                            name="minimum_discount_allowed"
                                            component={Input}
                                            placeholder="Minimum discount allowed"
                                            label="Minimum discount allowed"
                                            type="number"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                                // ampm={false}
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Valid Untill"
                                                name="valid_untill"
                                                value={validUntil}
                                                onChange={handleFromDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </div>
                                        
                                    </div>
                                </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => {
                                    handleSubmit()}}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => {
                                    setFieldValue('coupon_code', '')
                                    setFieldValue('minimum_cart_amount', '')
                                    setFieldValue('minimum_discount_allowed', '')
                                    setFieldValue('usage_limit', '')
                                    setSelectedDiscountType({})
                                    setSelectedProducts({})
                                    setValidUntil(null)
                                }}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
