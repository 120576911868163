import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { AddBanners } from "./AddBanners"
import { ListBanners } from "./ListBanners"


export const Banners = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Banners");
    

    return (
        <Switch>
            <Redirect
                exact={true}
                from="/banners"
                to="/banners/list"
            />
            <ContentRoute from="/banners/add" component={AddBanners} />
            <ContentRoute from="/banners/list" component={ListBanners} />
            <ContentRoute from="/banners/:id/edit" component={AddBanners} />
        </Switch>
    );
};
