import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { AddStores, ListStores } from "./index"
export const MerchantStores = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Stores");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/stores"
        to="/stores/list"
      />
      <ContentRoute from="/stores/add" component={AddStores} />
      <ContentRoute from="/stores/list" component={ListStores} />
      <ContentRoute from="/stores/:id/edit" component={AddStores} />
    </Switch>
  );
};
