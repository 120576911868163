import axios from "axios";
const baseURL = process.env.REACT_APP_API_URI;
const saveCat = (data, id) => {
    let url = baseURL + "/api/categories"
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const getCat = (id) => {
    let url = baseURL + "/api/categories"
    if (id) url += `/${id}`
    return axios.get(url);
}

const saveProduct = (data, id) => {
    let url = baseURL + "/api/products"
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const getProduct = (id) => {
    let url = baseURL + "/api/products"
    if (id) url += `/${id}`
    return axios.get(url);
}
const deleteProduct = (id) => {
    let url = baseURL + `/api/products/${id}`
    return axios.delete(url);
}
const getDiscounts = (id) => {
    let url = baseURL + `/api/products/${id}/product-prices`
    return axios.get(url);
}
const saveDiscounts = (id, data, discountId) => {
    let url = baseURL + `/api/products/${id}/product-prices`
    if (discountId) url += `/${discountId}`
    return axios.post(url, data);
}
const removeDiscounts = (id, discountId) => {
    let url = baseURL + `/api/products/${id}/product-prices/${discountId}`
    return axios.delete(url);
}

const getUserTypes = (id) => {
    let url = baseURL + `/api/admin/user-type`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveUserTypes = (data, id) => {
    let url = baseURL + `/api/admin/user-type`
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const delUserTypes = (id) => {
    let url = baseURL + `/api/admin/user-type`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveBanner = (data, id) => {
    let url = baseURL + "/api/banners"
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const getBanner = (id) => {
    let url = baseURL + "/api/banners"
    if (id) url += `/${id}`
    return axios.get(url);
}
const delBanner = (id) => {
    let url = baseURL + "/api/banners"
    if (id) url += `/${id}`
    return axios.delete(url);
}

const getOrders = (id, params) => {
    let url = baseURL + "/api/orders"
    if (id) url += `/${id}`
    return axios.get(url, {params});
}
const getOrdersStatus = (id) => {
    let url = baseURL + "/api/order-statuses"
    if (id) url += `/${id}`
    return axios.get(url);
}
const getDispatches = (timeslot, route) => {
    let url = baseURL + `/api/orders/time-slot/${timeslot}/routes/${route}`
    return axios.get(url);
}
const updateOrderStatus = (data, id) => {
    let url = baseURL + "/api/orders"
    if (id) url += `/${id}/status-update`
    return axios.put(url, data);
}

const getStore = (id) => {
    let url = baseURL + `/api/admin/stores`
    if (id) url += `/${id}`
    return axios.get(url);
}
const getLocations = (id) => {
    let url = baseURL + `/api/locations`
    if (id) url += `/${id}`
    return axios.get(url);
}

const getRoutes = (id) => {
    let url = baseURL + `/api/routes`
    if (id) url += `/${id}`
    return axios.get(url);
}

const getStoreById = (id) => {
    let url = baseURL + `/api/stores`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveStore = (data, id) => {
    let url = baseURL + `/api/stores`
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const delStore = (id) => {
    let url = baseURL + `/api/admin/stores`
    if (id) url += `/${id}`
    return axios.delete(url);
}

const getUsers = (page, perPage, search_key = null, search_value = null) => {
    // let query = `?page=${page}&per_page=${perPage}`
    let url = baseURL + `/api/users`

    return axios.get(url, {params:{
        page,
        perPage,
        search_key,
        search_value
    }});
}

const getUserById = (id) => {
    let url = baseURL + `/api/users`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveUser = (data, id) => {
    let url = baseURL + `/api/users`
    if (id) url += `/${id}`
    return axios.post(url, data);
}

const AddRewardPoints = (data, id) => {
    let url = baseURL + `/api/users`
    if (id) url += `/${id}/points`
    return axios.post(url, data);
}

const patchUser = (data, id) => {
    let url = baseURL + `/api/users`
    if (id) url += `/${id}`
    return axios.patch(url, data);
}

const getReports = (data, id) => {
    let url = baseURL + `/api/report`
    if (id) url += `/${id}`
    return axios.post(url, data);
}

const getTargetsForStore = (id) => {
    let url = baseURL + `/api/admin/target/store`
    if (id) url += `/${id}`
    return axios.get(url);
}
const getTargets = (id) => {
    let url = baseURL + `/api/admin/target`
    if (id) url += `/${id}`
    console.log(url)
    return axios.get(url);
}

const saveTargets = (data, id) => {
    let url = baseURL + "/api/admin/target"
    if (id) url += `/${id}`
    return axios.post(url, data);
}

const deleteTargets = (id) => {
    let url = baseURL + "/api/admin/target"
    if (id) url += `/${id}`
    return axios.delete(url);
}

const saveTimeSlots = (data, id) => {
    let url = baseURL + "/api/time-slots"
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const getTimeSlots = (id) => {
    let url = baseURL + "/api/time-slots"
    if (id) url += `/${id}`
    return axios.get(url);
}
const delTimeSlots = (id) => {
    let url = baseURL + "/api/time-slots"
    if (id) url += `/${id}`
    return axios.delete(url);
}
const getDrivers = (id) => {
    let url = baseURL + "/api/driver"
    if (id) url += `/${id}`
    return axios.get(url);
}
const assignDriver = (id, data) => {
    let url = baseURL + `/api/drivers/${id}/assign-orders`
    return axios.post(url, data);
}
const getCoupon = (id) => {
    let url = baseURL + `/api/coupons`
    if (id) url += `/${id}`
    return axios.get(url);
}
const getCouponById = (id) => {
    let url = baseURL + `/api/coupons`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveCoupon = (data, id) => {
    let url = baseURL + `/api/coupons`
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const delCoupon = (id) => {
    let url = baseURL + `/api/coupons`
    if (id) url += `/${id}`
    return axios.delete(url);
}
const getDriver = (id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.get(url);
}
const getDriverById = (id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveDriver = (data, id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const delDriver = (id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.delete(url);
}
const getDealProduct = (id) => {
    let url = baseURL + `/api/deals/${id}/products`
    // if (id) url += `/${id}`
    return axios.get(url);
}
const getDealProductById = (id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveDealProduct = (data, id) => {
    let url = baseURL + `/api/deals/${id}/products`
    // if (id) url += `/${id}`
    return axios.post(url, data);
}
const delDealProduct = (dealId, id) => {
    let url = baseURL + `/api/deals/${dealId}/products/${id}`
    // if (id) url += `/${id}`
    return axios.delete(url);
}
const getDeal = (id) => {
    let url = baseURL + `/api/deals`
    if (id) url += `/${id}`
    return axios.get(url);
}
const getDealById = (id) => {
    let url = baseURL + `/api/deals`
    if (id) url += `/${id}`
    return axios.get(url);
}
const saveDeal = (data, id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.post(url, data);
}
const delDeal = (id) => {
    let url = baseURL + `/api/driver`
    if (id) url += `/${id}`
    return axios.delete(url);
}




export const Category = {
    save: saveCat,
    get: getCat,
    getById: getCat
}
export const Banners = {
    save: saveBanner,
    get: getBanner,
    getById: getBanner,
    delete: delBanner
}

export const Product = {
    save: saveProduct,
    get: getProduct,
    getById: getProduct,
    delete: deleteProduct,
    discounts: getDiscounts,
    saveDiscounts: saveDiscounts,
    removeDiscounts: removeDiscounts
}

export const UserTypes = {
    get: getUserTypes,
    save: saveUserTypes,
    delete: delUserTypes,
    getById: getUserTypes
}

export const Orders = {
    get: getOrders,
    getById: getOrders,
    getDispatches: getDispatches,
    updateOrderStatus : updateOrderStatus,
    getOrdersStatus : getOrdersStatus
}

export const Store = {
    get: getStore,
    save: saveStore,
    delete: delStore,
    getById: getStoreById
}

export const Users = {
    get: getUsers,
    getById: getUserById,
    save: saveUser,
    patch: patchUser,
    AddRewardPoints : AddRewardPoints,
}

export const Targets = {
    get: getTargetsForStore,
    save: saveTargets,
    getById: getTargets,
    delete: deleteTargets
}

export const Reports = {
    get: getReports
}

export const TimeSlots = {
    get: getTimeSlots,
    save: saveTimeSlots,
    getById: getTimeSlots,
    delete: delTimeSlots
}
export const Locations = {
    get: getLocations
}

export const Routes = {
    get: getRoutes
}

export const Drivers = {
    get: getDrivers,
    assign: assignDriver
}

export const Coupon = {
    get: getCoupon,
    save: saveCoupon,
    delete: delCoupon,
    getById: getCouponById
}

export const Driver = {
    get: getDriver,
    save: saveDriver,
    delete: delDriver,
    getById: getDriverById
}

export const Deal = {
    get: getDeal,
    save: saveDeal,
    delete: delDeal,
    getById: getDealById
}

export const DealProduct = {
    get: getDealProduct,
    save: saveDealProduct,
    delete: delDealProduct,
    getById: getDealProductById
}




export async function login(email, password) {
    let url = baseURL + "/api/auth/login"
    const headers = { 'X-App-Type': 'super_admin' }
    return axios.post(url, { email_or_phone: email, password }, { headers: headers });
}
export async function logout() {
    let url = baseURL + "/api/auth/logout"
    return axios.post(url, {});
}

export async function GetopStores() {
    let url = baseURL + "/api/admin/top-stores"
    return axios.get(url);
}

export async function GetActivities() {
    let url = baseURL + "/api/admin/sales-activity"
    return axios.get(url);
}

