import React from "react";
import { Modal } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import * as alert from "../../../../../redux/reducer-extras/alertRedux"
function _AlertDialoges({ show }) {
    const dispatch = useDispatch()
    const alertStore = useSelector(
        ({ alert }) => alert,
        shallowEqual
    );
    const hideAlert = () => {
        alertStore.cb(false)
        dispatch(alert.actions.closeAlert({ open: false, message: null, type: null, cb: null }))
    }
    const closeAlert = () => {
        alertStore.cb(true)
        dispatch(alert.actions.closeAlert({ open: false, message: null, type: null, cb: null }))
    };
    const getModalTitle = ()=>{
        switch (alertStore.type){
            case 'alert': return alertStore.title ? alertStore.title : 'Alert';
            case 'confirm': return alertStore.title ? alertStore.title : 'Confirm'
            default: return alertStore.title ? alertStore.title : 'Alert';
        }
    }
    const getFooterBtns = () =>{
        switch (alertStore.type) {
            case 'alert': return (
                <div>
                    <button
                        type="button"
                        onClick={closeAlert}
                        className="btn btn-light btn-elevate"
                    >
                        OK
                    </button>
                </div>
            );
            case 'confirm': return (
                <div>
                    <button
                        type="button"
                        onClick={hideAlert}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={closeAlert}
                        className="btn btn-primary btn-elevate"
                    >
                        OK
                    </button>
                </div>
            )
            default: return '';
        }
    }
    return (
        <Modal
            show={alertStore.open}
            aria-labelledby="example-modal-sizes-title-lg"
            onHide={()=>{}}
        >
            {/*begin::Loading*/}
            {/* {isLoading && <ModalProgressBar />} */}
            {/*end::Loading*/}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {getModalTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{alertStore.message}</span>
            </Modal.Body>
            <Modal.Footer>
                {getFooterBtns()}
            </Modal.Footer>
        </Modal>
    );
}
export const AlertDialoges = injectIntl(connect(null, alert.actions)(_AlertDialoges))
