import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import {  ListCoupons, AddCoupons } from "./index"
export const Coupons = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Coupon Management");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/coupons"
        to="/coupons/list"
      />
      <ContentRoute from="/coupons/add" component={AddCoupons} />
      <ContentRoute from="/coupons/list" component={ListCoupons} />
      <ContentRoute from="/coupons/:id/edit" component={AddCoupons} />
    </Switch>
  );
};
