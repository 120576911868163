import React  from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { ListProducts, AddProducts } from "./index"


export const Products = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Products");
    return (
        <Switch>
            <Redirect
                exact={true}
                from="/products"
                to="/products/list"
            />
            <ContentRoute from="/products/add" component={AddProducts} />
            <ContentRoute from="/products/list" component={ListProducts} />
            <ContentRoute from="/products/:id/edit" component={AddProducts} />
        </Switch>
    );
};
