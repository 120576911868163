// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../_metronic/_partials/controls";
import { styles } from "./catStyles"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";
import { fileToBase64 } from "../../utlis/CommonMethods";
import { PrevElement } from "../../../_metronic/layout/components/extras/ImagePreview";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),
    // subactegoryId: Yup.string().required("subactegoryId is required"),
    // image: Yup.string().required("Image is required")
});

export function CatEditForm({
    data,
    btnRef,
    resetbtnRef,
    saveCategory,
    type,
    categories
}) {
    const [imagePrev, setImagePrev] = useState(null)
    const [selectedCatValue, setSelectedCatValue] = useState({})
    const [submit, setSubmit] = useState(false)
    const [imageTouched, setImageTouched] = useState(false)
    const imageUpload = React.createRef();
    
    useEffect(() => {
        setImagePrev(data.image)
        data.subactegoryId = data.parent_id
        if(data.subactegoryId != null && data.subactegoryId != ""){
            let value = categories.find((e) => e.value == data.subactegoryId)
            if (value) setSelectedCatValue(value)
        }
    }, [data, categories])
    const getCatOptions = () => {
        if (categories.length > 0) {
            return categories.map((opt) => {
                return (<option value={opt.value} key={'category-' + opt.value}>{opt.label}</option>)
            })
        }
        else return null
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    saveCategory(values);
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, values }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="Category name"
                                                label="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <AutoComplete
                                                value={selectedCatValue}
                                                label="Subcategory"
                                                name="subactegoryId"
                                                options={categories}
                                                onChange={(value) => setFieldValue("subactegoryId", value)} />
                                            {/* <Select name="subactegoryId" label="Subcategory">
                                                <option value="">Select</option>
                                                {getCatOptions()}
                                            </Select> */}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <label>Description</label>
                                            <Field
                                                name="description"
                                                as="textarea"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="col-lg-12 category-image-upload" >
                                        <div className="row m-b-1">
                                            <div className={'form-group image-upload ' + (errors["image"] && imageTouched ? 'error' : '')} title="Upload image" >
                                                {imagePrev == null ? (
                                                    <>
                                                        <SVG src={toAbsoluteUrl("/media/svg/custom/cloud-upload.svg")} onClick={() => {
                                                            imageUpload.current.click()
                                                            setImageTouched(true)
                                                        }} />
                                                        <p onClick={() => {
                                                            imageUpload.current.click()
                                                            setImageTouched(true)
                                                        }}>Click to upload the image</p>
                                                    </>) : (
                                                        <PrevElement
                                                            image={imagePrev}
                                                            change={() => imageUpload.current.click()}
                                                            cancel={() => {
                                                                setFieldValue("image", null)
                                                                imageUpload.current.value = null
                                                                setImagePrev(null)
                                                            }}
                                                        />
                                                    )
                                                }
                                                <input ref={imageUpload} onChange={async (event) => {
                                                    if (!event.currentTarget.files[0]) return
                                                    setFieldValue("image", event.currentTarget.files[0]);
                                                    let image = await fileToBase64(event.currentTarget.files[0])
                                                    setImagePrev(image)
                                                }} style={{ display: "none" }} type="file" accept="image/*" />
                                            </div>
                                            {errors["image"] && imageTouched ? <span className="image-error-span">Image is required</span> : ''}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onClick={() => {
                                    setImageTouched(true)
                                    handleSubmit()
                                }}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => {
                                    if (type != 'edit') setImagePrev(null)
                                    setFieldValue("description", "");
                                    setFieldValue("subactegoryId", "");
                                    setFieldValue("name", "");
                                    setSelectedCatValue({})
                                    imageUpload.current.value = null
                                }}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
