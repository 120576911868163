import React, {useState, useRef} from "react";
import {Button, Modal} from 'react-bootstrap';
import {EditForm} from './PointForm'


export default function App (props) {

    const [show, setShow] = useState(false)
    const btnRef = useRef();

    const {AddPoints} = props

    const saveProductClick = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
          <Button variant="primary" onClick={handleShow}>
            Add Purple Coins 
          </Button>
  
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Purple Coins</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditForm 
                btnRef={btnRef}
                AddPoints={AddPoints}
                handleClose={handleClose}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" 
                onClick={saveProductClick} 
                >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
    )
}