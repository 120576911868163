import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import {  ListDrivers, AddDrivers } from "./index"
export const Drivers = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Driver Management");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/drivers"
        to="/drivers/list"
      />
      <ContentRoute from="/drivers/add" component={AddDrivers} />
      <ContentRoute from="/drivers/list" component={ListDrivers} />
      <ContentRoute from="/drivers/:id/edit" component={AddDrivers} />
    </Switch>
  );
};
