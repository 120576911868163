import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { DealProductTable } from "./DealProTable";
import { DealProduct, Deal } from "../../utlis/API"
import { useDispatch } from "react-redux";
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as alert from "../../../redux/reducer-extras/alertRedux"
import * as toaster from "../../../redux/reducer-extras/toasterRedux"

export const ListDealProducts = (props) => {
    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 }
        ],
        sizePerPage: 5,
        page: 1,
    };
    const dispatch = useDispatch()
    const [init, setInit] = useState(true);
    const [dealId, setId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dealProducts, setDealProducts] = useState([]);
    const [dealData, setDealData] = useState({});
    const [dealName, setDealName] = useState(null);
    const [tempDealProducts, setTempDealProducts] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

    const columns = [
        {
            dataField: "id",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                edit: false,
                delete: true,
                openEditProductPage: (id) => null,
                // openEditProductPage: (id) => { props.history.push(`deals/product/${id}/edit`) },
                openDeleteProductDialog: (id) => { deleteProduct(id) },
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    useEffect(()=>{
        onTableChanged('page', _paginationOptions)
    }, [tempDealProducts])

    useEffect(() => {
        try {
            console.log("props.match.params",props.match.params)
            let id = props.match.params.id
            if (id !== undefined) fetchData(id)
            if (id !== undefined) fetchDealData(id)
            
        }
        catch (e) {
            console.log(e)
        }
    }, [init])

    const fetchDealData = (id) => {
        setLoading(true)
         Deal.getById(id).then((response) => {
            setId(id)
            setLoading(false)
            setDealData({...response.deal})
            let dealname = response.deal.name
            setDealName(dealname)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    
    const proceedDelete = (id) => {
        DealProduct.delete(dealId, id).then((res)=>{
            let _tempDealProducts = [...tempDealProducts]
            let index = _tempDealProducts.findIndex((e)=> e.id == id)
            if (index != -1) _tempDealProducts.splice(index, 1)
            setTempDealProducts([..._tempDealProducts])
            dispatch(toaster.actions.openToaster({ open: true, message: 'Deal product successfully deleted', type: 'success' }))
        }).catch(e=>{
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete deal product', type: 'error' }))
        })
    }
    const deleteProduct = (id) => {
        dispatch(alert.actions.showAlert({
            open: true,
            message: 'Are you sure you want to delete this deal product',
            type: 'confirm',
            title: 'Delete deal product',
            cb: (status) => {
                if (status) proceedDelete(id)
            }
        }))
    }
    const fetchData = (id) => {
        setLoading(true)
        DealProduct.get(id).then((r) => {
            setId(id)
            setLoading(false)
            setTempDealProducts([...r])
            let dealProducts = r.slice(0, paginationOptions.sizePerPage)
            paginationOptions.totalSize = r.length
            setDealProducts(dealProducts)
            setPaginationOptions(paginationOptions)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        let start = (pageNumber - 1) * sizePerPage
        let dealProducts = tempDealProducts.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        _paginationOptions.totalSize = tempDealProducts.length
        setDealProducts(dealProducts)
        setPaginationOptions(_paginationOptions)
    }

    return (
        <Card>
            {loading && (<ModalProgressBar />)}
            <CardHeader title={dealName}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => props.history.push(`/deals/${dealId}/product/add`)}
                    >
                        Add new Deal Product
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <DealProductTable
                    entities={dealProducts}
                    onChange={onTableChanged}
                    paginationOptions={_paginationOptions}
                    columns={columns}
                    listLoading={loading} />
            </CardBody>
        </Card>
    )
};


