
export const MenuItems = [
    {
        path: 'dashboard',
        label: 'Dashboard',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: []
    },
    {
        path: 'orders',
        label: 'Orders',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Shopping/Cart3.svg"
    },
    {
        path: 'dispatches',
        label: 'Dispatches',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Communication/Sending.svg"
    },
    {
        path: 'customers',
        label: 'Users',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/User-folder.svg",
        subMenuItems: []
    },
    {
        path: 'reports',
        label: 'Reports',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg"
    },
    {
        path: 'stores',
        label: 'Stores',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Home/Home.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'products',
        label: 'Products',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Home/Box.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'user-types',
        label: 'User types',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/General/User.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'categories',
        label: 'Categories',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Shopping/Box2.svg",

        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'banners',
        label: 'Banners',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Design/Image.svg",

        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'targets',
        label: 'Targets',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Shopping/Box2.svg",

        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'time-slots',
        label: 'Time slots',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Code/Time-schedule.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'coupons',
        label: 'Coupon Management',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Code/Time-schedule.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'drivers',
        label: 'Driver Management',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Code/Time-schedule.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
        }]
    },
    {
        path: 'deals',
        label: 'Deal Management',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Code/Time-schedule.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
        }]
    }
]