// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { fileToBase64 } from "../../utlis/CommonMethods";
import { PrevElement } from "../../../_metronic/layout/components/extras/ImagePreview";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
// Validation schema
const ProductEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),
    subcategoryId: Yup.string().required("Subcategory is required"),
    qtyLabel: Yup.string().required("Quantity label is required"),
    stock: Yup.number()
            .required("Stock is required")
            .min(0, 'Minmum value is 0'),
    qtyIncrementor: Yup.number()
            .required("Quantity incrementer is required")
            .min(0, 'Minmum value is 0'),
    minQty: Yup.number()
            .required("Quantity incrementer is required")
            .min(0, 'Minmum value is 0'),
    subcategoryId: Yup.string().required("Subcategory is required"),
    // categoryId: Yup.string().required("Category is required"),
    sku: Yup.string().required("SKU is required"),
    mrp: Yup.number()
        .required("MRP is required")
        .min(0, 'Minmum value is 0'),
    image: Yup.string().required("Image is required"),
    // strikethrough_price: Yup.string()
    // .nullable()
    // .required("Offer price is required")
});

export function ProductEditForm({
    data,
    btnRef,
    resetbtnRef,
    saveProduct,
    type,
    categories
}) {
    const [selectedSubCatValue, setSelectedSubCatValue] = useState({})
    const [imageTouched, setImageTouched] = useState(false)
    const imageUpload = React.createRef();
    const [imagePrev, setImagePrev] = useState(null)
    // console.log('data', data);
    useEffect(() => {
        setImagePrev(data.image)
        data.subcategoryId = data.product_category_id
        if (data.subcategoryId != null && data.subcategoryId !== "") {
            let value = categories.find((e) => e.value === data.subcategoryId)
            if (value) setSelectedSubCatValue(value)
        }
    }, [data, categories])
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={ProductEditSchema}
                onSubmit={(values) => {
                    // console.log(values)
                    saveProduct(values);
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, touched, ...others }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="row image-uploader-container">
                                <div className="col-lg-12">
                                    <div className="form-group row col-lg-8 p-0">
                                        <div className="col-lg-12">
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="Product name"
                                                label="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row col-lg-8 p-0">
                                        <div className="col-lg-12">
                                            <label>Description</label>
                                            <Field
                                                name="description"
                                                as="textarea"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-6">
                                            <Field
                                                name="sku"
                                                component={Input}
                                                placeholder="SKU"
                                                label="SKU"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Field
                                                name="mrp"
                                                component={Input}
                                                placeholder="MRP"
                                                label="MRP"
                                                type="number"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-lg-6">
                                            <Field
                                                name="qtyLabel"
                                                component={Input}
                                                placeholder="Quantity label"
                                                label="Quantity label"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Field
                                                name="stock"
                                                component={Input}
                                                placeholder="Stock"
                                                label="Stock"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-6">
                                            <Field
                                                name="minQty"
                                                component={Input}
                                                placeholder="Minimum quantity"
                                                label="Minimum Qty"
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Field
                                                name="qtyIncrementor"
                                                component={Input}
                                                placeholder="Quantity incrementor"
                                                label="Quantity incrementor"
                                                type="number"
                                            />
                                        </div>

                                    </div>
                                    {/* <div className="form-group row">
                                        <div className="col-lg-12">
                                            <AutoComplete 
                                                value={selectedCatValue}
                                                label="Category"
                                                name="categoryId"
                                                options={categories}
                                                onChange={(value) => setFieldValue("categoryId", value)} />
                                        </div>
                                    </div> */}
                                    
                                    <div className="form-group row">
                                        <div className="col-lg-6">
                                            <Field
                                                name="strikethrough_price"
                                                component={Input}
                                                placeholder="Offer Price"
                                                label="Offer Price"
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <AutoComplete
                                                value={selectedSubCatValue}
                                                label="Subcategory"
                                                name="subcategoryId"
                                                options={categories}
                                                onChange={(value) => setFieldValue("subcategoryId", value)} />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        style={{ display: "none" }}
                                        ref={btnRef}
                                        onClick={() => {
                                            setImageTouched(true)
                                            handleSubmit()
                                        }}
                                    ></button>
                                    <button
                                        type="button"
                                        style={{ display: "none" }}
                                        ref={resetbtnRef}
                                        onClick={() => {
                                            console.log('reset btn')
                                            if (type != 'edit') setImagePrev(null)
                                            setFieldValue("description", "");
                                            setFieldValue("subcategoryId", "");
                                            setFieldValue("name", "");
                                            setFieldValue("sku", "");
                                            setFieldValue("mrp", "");
                                            setFieldValue("mrp", "");
                                            setFieldValue("qtyLabel", "");
                                            setFieldValue("stock", "");
                                            setFieldValue("qtyIncrementor", "");
                                            setFieldValue("minQty", "");
                                            setFieldValue("strikethrough_price", "")
                                            setSelectedSubCatValue({})
                                            // resetForm()
                                            imageUpload.current.value = null
                                            console.log(selectedSubCatValue)
                                        }}
                                    ></button>
                                </div>
                                <div className="col-lg-4 image-wrapper">
                                    <div className="col-lg-12 category-image-upload" >
                                        <div className="row m-b-1">
                                            <div className={'form-group image-upload ' + (errors["image"] && imageTouched ? 'error' : '')} title="Upload image" >
                                                {imagePrev === null || imagePrev === '' ? (
                                                    <>
                                                        <SVG src={toAbsoluteUrl("/media/svg/custom/cloud-upload.svg")} onClick={() => {
                                                            imageUpload.current.click()
                                                            setImageTouched(true)
                                                        }} />
                                                        <p onClick={() => {
                                                            imageUpload.current.click()
                                                            setImageTouched(true)
                                                        }}>Click to upload the image</p>
                                                    </>) : (
                                                        <PrevElement
                                                            image={imagePrev}
                                                            change={() => imageUpload.current.click()}
                                                            cancel={() => {
                                                                setFieldValue("image", null)
                                                                imageUpload.current.value = null
                                                                setImagePrev(null)
                                                            }}
                                                        />
                                                    )
                                                }
                                                <input ref={imageUpload} onChange={async (event) => {
                                                    if (!event.currentTarget.files[0]) return
                                                    setFieldValue("image", event.currentTarget.files[0]);
                                                    let image = await fileToBase64(event.currentTarget.files[0])
                                                    setImagePrev(image)
                                                }} style={{ display: "none" }} name="image" type="file" accept="image/*" />
                                            </div>
                                            {errors["image"] && imageTouched ? <span className="image-error-span">Image is required</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
