import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { connect } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux";

import { TargetEditForm } from "./TargetsEditForm";
import { Targets, Store } from "../../utlis/API"
import { injectIntl } from "react-intl";

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const getTargetPeriod = (type, d) => {
    if (type == 'month') {
        d = new Date()
        let year = d.getFullYear()
        console.log(type, d)
        return `${year}-${months.indexOf(d)}`

    }
    d = new Date(d)
    let month = ('0' + (d.getMonth() + 1)).slice(-2)
    let date = ('0' + d.getDate()).slice(-2)
    let year = d.getFullYear()
    return `${year}-${month}-${date}`
}
const initialData = {
    storeId: "",
    targets: [
        {
            type: "",
            period: getTargetPeriod(null, new Date()),
            amount: "",
            month: ""
        }
    ]
}
const loopErros = (data) => {
    if (data.status == 409) {
        if (data.data) {
            return data.data.message ? data.data.message : 'Failed to save target'
        }
    }
    else return null
}
export const _AddTargets = (props) => {
    const [data, setData] = useState({ ...initialData });
    const [store, setStore] = useState({})
    const [saving, setSaving] = useState(false);
    const [type, setType] = useState('add')
    const [stores, setStores] = useState([])
    const [targetTypes,] = useState(["day", "month"].map(e => ({ value: e, label: e.toUpperCase() })))
    const back = () => {
        props.history.push('/targets/list' + props.location.search)
    }

    const save = (values) => {
        setSaving(true)
        try {
            if (values.storeId == undefined) {
                props.openToaster({ open: true, message: 'Please select a store', type: 'error' })
                return
            }
            let data = {
                "store_id": values.storeId,
                "target_type": values.type,
                "target_amount": values.amount
            }
            if (values.id != undefined) data.id = values.id
            if (values.type == 'day') data.target_period = getTargetPeriod(values.type, values.period)
            else data.target_period = values.month
            _save(data)
        }
        catch (e) {
            console.log(e)
        }

    }
    const _save = (data) => {
        if (data.id) data['_method'] = 'PUT'
        Targets.save(data, data.id).then((res) => {
            props.openToaster({ open: true, message: 'Target successfully saved', type: 'success' })
            storeChanged(data.store_id)
            setSaving(false)
        }).catch(err => {
            err = JSON.parse(err.message)
            console.log(err)
            let msg = loopErros(err)
            if (msg == null) msg = 'Failed to save Target'
            props.openToaster({ open: true, message: msg, type: 'error' })
            setSaving(false)
        })
    }
    const resetbtnRef = useRef()
    useEffect(() => {
        try {
            fetchStores().then(() => { })
        }
        catch (e) {
            console.log(e)
        }
    }, [props.location])
    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        if (query.get('storeId') !== null || query.get('storeId') != undefined) {
            handleStoreChange(query.get('storeId'))
        }
    }, [stores])
    const fetchStores = () => {
        return new Promise((resolve, reject) => {
            Store.get().then((response) => {
                const stores = response.stores.map((e) => ({ label: e.name, value: e.id }))
                setStores([...stores])
                resolve()
            }).catch(e => {
                reject()
            })
        })
    }

    const handleStoreChange = (id) => {
        let value = stores.find((e) => e.value == id)
        if (value) setStore({ ...value })
        storeChanged(id)
    }
    const storeChanged = (storeId) => {
        setSaving(true)
        Targets.get(storeId).then((response) => {
            setSaving(false)
            let data = { ...initialData }
            data.storeId = storeId
            let targets = []
            response.target.forEach((e) => {
                if (e.target_type == 'week') return
                let o = {
                    storeId: e.store_id,
                    type: e.target_type,
                    amount: e.target_amount,
                    period: e.target_period,
                    id: e.id
                }
                if (o.type == 'month') o.month = e.target_period
                targets.push(o)
            })
            targets.push({
                type: "",
                period: getTargetPeriod(null, new Date()),
                amount: "",
                month: ""
            })
            data.targets = [...targets]
            setData({ ...data })
        }).catch(e => {
            setSaving(false)
            props.openToaster({ open: true, message: 'Failed to get Targets', type: 'error' })
        })
    }
    const deleteTraget = (values) => {
        console.log(values)
        setSaving(true)
        // Confirmation
        Targets.delete(values.id).then((response) => {
            console.log(response)
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <Card>
            {saving && (<ModalProgressBar />)}
            <CardHeader title={store.value == undefined ? 'Select a store' : 'Tragets of ' + store.label}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={back}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left"></i>
                    Back
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <TargetEditForm
                    data={data}
                    resetbtnRef={resetbtnRef}
                    save={save}
                    type={type}
                    targetTypes={targetTypes}
                    stores={[...stores]}
                    storeChanged={handleStoreChange}
                    deleteTraget={deleteTraget}
                    store={{ ...store }}
                >
                </TargetEditForm>
            </CardBody>
        </Card>
    )
};


export const AddTargets = injectIntl(connect(null, toaster.actions)(_AddTargets))