/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const dateTimePipe = (d)=>{
    let o = new Intl.DateTimeFormat("en", {
        dateStyle: "full"
    });
    return o.format(new Date(d))
}
export function HeaderMenu({ layoutProps }) {
    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <p>{dateTimePipe(new Date())}</p>
        {/* <ul className={`menu-nav ${layoutProps.ulClasses}`}> */}
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
        {/* </ul> */}
    </div>;
}
