import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Store } from "../../utlis/API"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import * as alert from "../../../redux/reducer-extras/alertRedux";
const _ListStores = (props) => {
  const dispatch = useDispatch()
  const paginationOptions = {
    custom: true,
    totalSize: 100,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: 5,
    page: 1,
  };
  const columns = [
    {
      dataField: "id",
      text: "SL NO",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row, rowIndex) => {
        return (<span>{rowIndex + 1}</span>)
      }
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "desc",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditProductPage: (id) => { 
          // console.log("Edit")
          props.history.push(`/stores/${id}/edit`)
         },
        openDeleteProductDialog: (id) => { 
          // deleteItem(id)
          console.log('delete')
         },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const [init, setInit] = useState(true);
  const [stores, setStores] = useState([]);
  const [tempStores, setTempStores] = useState([]);
  const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    onTableChanged('page', _paginationOptions)
  }, [tempStores])
  useEffect(() => {
    setLoading(true)
    Store.get().then((response) => {
      try {

        setTempStores([...response.stores])
        let stores = response.stores.slice(0, paginationOptions.sizePerPage)
        paginationOptions.totalSize = response.stores.length
        setStores(stores)
        setPaginationOptions(paginationOptions)
      }
      catch (e) {
        console.log(e)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
    })

  }, [init])
  const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    let start = (pageNumber - 1) * sizePerPage
    let stores = tempStores.slice(start, start + sizePerPage)
    _paginationOptions.sizePerPage = sizePerPage
    _paginationOptions.page = pageNumber
    setStores(stores)
    setPaginationOptions(_paginationOptions)
  }
  const deleteItem = (id) => {
    dispatch(alert.actions.showAlert({
      open: true,
      message: 'Are you sure you want to delete this store',
      type: 'confirm',
      title: 'Delete product',
      cb: (status) => {
        if (status) proceedDelete(id)
      }
    }))
  }
  const proceedDelete = (id) => {
    Store.delete(id).then((res) => {
      let _tempStores = [...tempStores]
      let index = _tempStores.findIndex((e) => e.id == id)
      if (index != -1) _tempStores.splice(index, 1)
      setTempStores([..._tempStores])
      dispatch(toaster.actions.openToaster({ open: true, message: 'Store successfully deleted', type: 'success' }))
    }).catch(e => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete store', type: 'error' }))
    })
  }
  return (
    <Card>
      {loading && (<ModalProgressBar />)}
      <CardHeader title="Stores list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/stores/add')}
          >
            Add new store
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CommonTable
          entities={stores}
          onChange={onTableChanged}
          paginationOptions={_paginationOptions}
          columns={columns}
          listLoading={loading}
        />
      </CardBody>
    </Card>
  )
};
export const ListStores = injectIntl(connect(null, toaster.actions)(_ListStores))

