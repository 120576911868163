import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { connect } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux";

import { UserTypeEditForm } from "./UserTypeEditForm";
import { UserTypes } from "../../utlis/API"
import { injectIntl } from "react-intl";

export const _AddUserTypes = (props) => {
  const [data, setData] = useState({ name: '', });
  const [init] = useState(true);
  const [type, setType] = useState('add')
  const [typeId, setId] = useState(null)
  const back = () => {
    props.history.push('/user-types/list')
  }

  const save = (values) => {
    let data = { ...values }
    if (type === 'edit') data['_method'] = 'PUT'
    UserTypes.save(data, typeId).then((res) => {
      props.openToaster({ open: true, message: 'User type successfully saved', type: 'success' })
      if (type === 'add') resetbtnRef.current.click()
      else back()
    }).catch(err => {
      props.openToaster({ open: true, message: 'Failed to save user type', type: 'error' })
      console.log(err)
    })
  }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }
  useEffect(() => {
    try {
      let id = props.match.params.id
      if (id !== undefined) fetchData(id)
    }
    catch (e) {
      console.log(e)
    }
  }, [init])
  const fetchData = (id) => {
    UserTypes.getById(id).then((r) => {
      setId(id)
      r.userType.description = r.userType.desc
      if (r.userType.img === '') r.userType.img = 0
      r.userType.image = r.userType.img
      delete r.userType.desc
      delete r.userType.img
      setData(r.userType)
      setType('edit')
    }).catch(e => {
      props.openToaster({ open: true, message: 'Failed to get user type', type: 'error' })
    })
  }
  return (
    <Card>
      <CardHeader title={type !== 'edit' ? 'Add user type' : 'Edit user type'}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={back}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveClick}
          >
            Save
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <UserTypeEditForm
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            save={save}
            type={type}
          >
          </UserTypeEditForm>
        </div>
      </CardBody>
    </Card>
  )
};


export const AddUserTypes = injectIntl(connect(null, toaster.actions)(_AddUserTypes))