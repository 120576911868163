import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Deal } from "../../utlis/API";
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import * as alert from "../../../redux/reducer-extras/alertRedux";
const _ListDeals = (props) => {
  const dispatch = useDispatch()
  const paginationOptions = {
    custom: true,
    totalSize: 100,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: 5,
    page: 1,
  };
  const columns = [
    {
      dataField: "id",
      text: "SL NO",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row, rowIndex) => {
        return (<span>{rowIndex + 1}</span>)
      }
    },
    {
      dataField: "name",
      text: "Deal Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "is_active",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row, rowIndex) => {
        return (
            <span
            //  className={'user-status ' (cellContent == "1" ? 'approve' : 'reject')}
             >
                {cellContent === "1" ? 'Active' : 'In active'}
            </span>
        )
    }
    },
    {
        dataField: "action",
        text: "Actions",
        formatter: ActionsColumnFormatter,
        formatExtraData: {
            delete: false,
            edit: false,
            view: true,
            viewItem: (id) => { props.history.push(`/deals/${id}/products/list`) },
            openEditProductPage: (id) => null,
            openDeleteProductDialog: (id) => null,
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
  ];

  const [init, setInit] = useState(true);
  const [deals, setDeals] = useState([]);
  const [products, setProducts] = useState([]);
  const [tempDeals, setTempDeals] = useState([]);
  const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    onTableChanged('page', _paginationOptions)
  }, [tempDeals])
  useEffect(() => {
    setLoading(true)
    Deal.get().then((response) => {
      try {
        setTempDeals([...response.deal])
        let deals = response.deal.slice(0, paginationOptions.sizePerPage)
        paginationOptions.totalSize = response.deal.length
        setDeals(deals)
        setPaginationOptions(paginationOptions)
      }
      catch (e) {
        console.log(e)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
    })
  }, [init])
  const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    let start = (pageNumber - 1) * sizePerPage
    let deals = tempDeals.slice(start, start + sizePerPage)
    _paginationOptions.sizePerPage = sizePerPage
    _paginationOptions.page = pageNumber
    setDeals(deals)
    setPaginationOptions(_paginationOptions)
  }
  const deleteItem = (id) => {
    dispatch(alert.actions.showAlert({
      open: true,
      message: 'Are you sure you want to delete this deal',
      type: 'confirm',
      title: 'Delete Deal',
      cb: (status) => {
        if (status) proceedDelete(id)
      }
    }))
  }
  const proceedDelete = (id) => {
    Deal.delete(id).then((res) => {
      let _tempDeals = [...tempDeals]
      let index = _tempDeals.findIndex((e) => e.id == id)
      if (index != -1) _tempDeals.splice(index, 1)
      setTempDeals([..._tempDeals])
      dispatch(toaster.actions.openToaster({ open: true, message: 'Deal successfully deleted', type: 'success' }))
    }).catch(e => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete deal', type: 'error' }))
    })
  }
  return (
    <Card>
      {loading && (<ModalProgressBar />)}
      <CardHeader title="Deals">
        {/* <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/deals/add')}
          >
            Add new deal
          </button>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <CommonTable
          entities={deals}
          onChange={onTableChanged}
          paginationOptions={_paginationOptions}
          columns={columns}
          listLoading={loading}
        />
      </CardBody>
    </Card>
  )
};
export const ListDeals = injectIntl(connect(null, toaster.actions)(_ListDeals))

