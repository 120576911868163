// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
// Validation schema
const TimeSlotSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),
    // subactegoryId: Yup.string().required("subactegoryId is required"),
    // from: Yup.string().required("from is required"),
    // to: Yup.string().required("from is required")
});

export function BannerEditForm({
    data,
    btnRef,
    resetbtnRef,
    type,
    save
}) {
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const dispatch = useDispatch()
    const handleFromDateChange = (date) => {
        date = setDate(date)
        setSelectedFromDate(date);
        setSelectedToDate(null);
    };
    const handleToDateChange = (date) => {
        date = setDate(date)
        let from = new Date(selectedFromDate)
        if (from > date) {
            console.log("From date is grater that to")
            dispatch(toaster.actions.openToaster({ open: true, message: 'End time is should be greater than start time.', type: 'error' }))
            return
        }
        setSelectedToDate(date);
    };

    useEffect(() => {
        if (data.from != "" && data.from != undefined) setSelectedFromDate(new Date(data.from))
        if (data.to != "" && data.to != undefined) setSelectedToDate(new Date(data.to))
    }, [data])
    const formatTime = () => {
        let from = new Date(selectedFromDate)
        let to = new Date(selectedToDate)
        let fromOut = `${('0' + from.getHours()).slice(-2)}:${('0' + from.getMinutes()).slice(-2)}`
        let toOut = `${('0' + to.getHours()).slice(-2)}:${('0' + to.getMinutes()).slice(-2)}`
        return { from: fromOut, to: toOut }
    }
    const setDate = (date) => {
        date = new Date(date)
        date.setDate(0)
        date.setMonth(0)
        date.setFullYear(0)
        date.setSeconds(0)
        return date
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={TimeSlotSchema}
                onSubmit={(values) => {
                    let time = formatTime()
                    values.to = time.to
                    values.from = time.from
                    save(values);
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, values }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="Name"
                                                label="name"
                                            />
                                        </div>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <label>From</label>
                                                <KeyboardTimePicker
                                                    // ampm={false}
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Select From"
                                                    name="from"
                                                    value={selectedFromDate}
                                                    required
                                                    onChange={handleFromDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>To</label>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Select To"
                                                    name="to"
                                                    required
                                                    value={selectedToDate}
                                                    onChange={handleToDateChange}
                                                    mintime={selectedFromDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onClick={() => {
                                    if (selectedFromDate == 'Invalid Date' || selectedFromDate == null) setSelectedFromDate("-");
                                    if (selectedToDate == 'Invalid Date' || selectedToDate == null) setSelectedToDate("-");
                                    handleSubmit()
                                }}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => {
                                    setFieldValue("name", "");
                                    setSelectedFromDate(null)
                                    setSelectedToDate(null)
                                }}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
