import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { Banners } from "../../utlis/API"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import * as alert from "../../../redux/reducer-extras/alertRedux";
const _ListBanners = (props) => {
    const dispatch = useDispatch()
    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 }
        ],
        sizePerPage: 5,
        page: 1,
    };
    const imageView = (cellContent, row, rowIndex,) => {
        return (
            <img src={cellContent} className="table-image" alt={cellContent} />
        )
    }
    const columns = [
        {
            dataField: "id",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cellContent, row, rowIndex) => {
                return (<span>{rowIndex + 1}</span>)
            }
        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "img",
            text: "Image",
            sort: true,
            sortCaret: sortCaret,
            formatter: imageView
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                openEditProductPage: (id) => { props.history.push(`/banners/${id}/edit`) },
                openDeleteProductDialog: (id) => { deleteItem(id) },
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    const [init, setInit] = useState(true);
    const [banners, setBanners] = useState([]);
    const [tempBanners, setTempBanners] = useState([]);
    const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        onTableChanged('page', _paginationOptions)
    }, [tempBanners])
    useEffect(() => {
        setLoading(true)
        Banners.get().then((response) => {
            try {
                setTempBanners([...response.banner])
                let banners = response.banner.slice(0, paginationOptions.sizePerPage)
                paginationOptions.totalSize = response.banner.length
                setBanners(banners)
                setPaginationOptions(paginationOptions)
            }
            catch (e) {
                console.log(e)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
        })

    }, [init])
    const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        const pageNumber = page || 1;
        let start = (pageNumber - 1) * sizePerPage
        let banners = tempBanners.slice(start, start + sizePerPage)
        _paginationOptions.sizePerPage = sizePerPage
        _paginationOptions.page = pageNumber
        setBanners(banners)
        setPaginationOptions(_paginationOptions)
    }
    const deleteItem = (id) => {
        dispatch(alert.actions.showAlert({
            open: true,
            message: 'Are you sure you want to delete this banner',
            type: 'confirm',
            title: 'Delete product',
            cb: (status) => {
                if (status) proceedDelete(id)
            }
        }))
    }
    const proceedDelete = (id)=>{
        Banners.delete(id).then((res) => {
            let _tmpBanners = [...tempBanners]
            let index = _tmpBanners.findIndex((e) => e.id == id)
            if (index != -1) _tmpBanners.splice(index, 1)
            setTempBanners([..._tmpBanners])
            dispatch(toaster.actions.openToaster({ open: true, message: 'Banner successfully deleted', type: 'success' }))
        }).catch(e => {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete banner', type: 'error' }))
        })
    }
    return (
        <Card>
            {loading && (<ModalProgressBar />)}
            <CardHeader title="Banners list">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => props.history.push('/banners/add')}
                    >
                        Add new banner
          </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <CommonTable
                    entities={banners}
                    onChange={onTableChanged}
                    paginationOptions={_paginationOptions}
                    columns={columns}
                    listLoading={loading}
                />
            </CardBody>
        </Card>
    )
};
export const ListBanners = injectIntl(connect(null, toaster.actions)(_ListBanners))

