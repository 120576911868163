import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { ListOrders } from "./ListOrders"


export const Orders = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Orders");
    return (
        <Switch>
            <Redirect
                exact={true}
                from="/orders"
                to="/orders/list"
            />
            <ContentRoute from="/orders/list" component={ListOrders} />
        </Switch>
    );
};
