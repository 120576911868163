import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { ProductEditForm } from "./ProEditForm";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Category, Product, UserTypes } from "../../utlis/API"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux"
import * as _usedUserTypes from "../../../redux/reducer-extras/productsUserTypesRedux"
import { ProductDiscountForm } from "./ProDiscountForm"
const initialData = {
    name: '',
    sku: '',
    mrp: '',
    subcategoryId: '',
    image: '',
    description: '',
    qtyLabel:'',
    stock:'',
    qtyIncrementor:'',
    minQty:'',
    strikethrough_price: '',
}
const initialDiscountData = {
    userTypeId: 3,
    price: 200,
}
export const AddProducts = (props) => {
    const dispatch = useDispatch()
    const [data, setProduct] = useState(initialData);
    const [discounts, setDiscounts] = useState(initialDiscountData);
    const [userTypes, setUserTypes] = useState([]);
    const [saving, setSaving] = useState(false);
    const [categories, setCategories] = useState([])
    const [tabIndex, setTabIndex] = useState(0);
    const [type, setType] = useState('add')
    const [productId, setId] = useState(null)
    const btnRef = useRef();
    const resetbtnRef = useRef()
    const usedUserTypes = useSelector(
        ({ usedUserTypes }) => usedUserTypes.used,
        shallowEqual
    );

    const back = () => props.history.push({pathname : '/products/list', state : {page : props.location.state != undefined ? props.location.state.page : ""}})
    const saveProductClick = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };
    const resetForm = () => {
        if (resetbtnRef && resetbtnRef.current) {
            resetbtnRef.current.click()
        }
    }
    const handleChange = (event, newtabIndex) => {
        setTabIndex(newtabIndex);
    }

    const saveProduct = (values) => {
        setSaving(true)
        var data = new FormData();
        data.append("name", values.name);
        data.append("desc", values.description);
        data.append("sku", values.sku);
        data.append("mrp", values.mrp);
        data.append("qty_label", values.qtyLabel);
        data.append("stock", values.stock);
        data.append("qty_incrementor", values.qtyIncrementor);
        data.append("min_qty", values.minQty);
        data.append("sub_category_id", values.subcategoryId);
        data.append("strikethrough_price", values.strikethrough_price);
        if (typeof values.image !== "string") data.append('img', values.image)
        if(type === 'edit') data.append('_method', 'PUT')
        Product.save(data, productId).then((r) => {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Product successfully saved', type: 'success' }))
            if (type === 'edit') {
                // For adding discounts ; Redirection blocked.
                props.history.push(`/products/${r.product.id}/edit`)
            }
            else resetForm()
            setSaving(false)
            // else back()
        }).catch((err) => {
            console.log(err)
            setSaving(false)
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to save product', type: 'error' }))
        })
    }
    useEffect(() => {
        (()=>{
            reInitializeComponent()
        })()
    }, [props.location]);
    // useEffect(() => {
    //     reInitializeComponent()
    // }, [init])
    const reInitializeComponent = () => {
        console.log("reInitializeComponent", props.match.params.id)
        findCategories()
        try {
            let id = props.match.params.id
            if (id !== undefined) {
                fetchData(id)
                Promise.all([findDiscounts(id), findUserTypes()]).then((result) => {
                    let discounts = result[0]
                    let userTypes = result[1]
                    let used = []
                    discounts.forEach((discount) => used.push(discount.userType))
                    setDiscounts(discounts)
                    setUserTypes(userTypes)
                    dispatch(_usedUserTypes.actions.setStore(used))
                }).catch(err => {
                    console.log(err)
                })
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    const saveDiscounts = () => {

    }


    const findCategories = () => {
        Category.get().then((response) => {
            try {
                let categories = response.productCategories.map(cat => {
                    return { value: cat.id, label: cat.name }
                })
                setCategories([...categories])
            }
            catch (e) {
                console.log(e)
            }
        }).catch((err) => {

        })
    }
    const fetchData = (id) => {
        Product.getById(id).then((r) => {
            setId(id)
            r.product.description = r.product.desc
            if (r.product.img === '') r.product.img = 0
            r.product.image = r.product.img

            r.product.qtyLabel = r.product.qty_label
            r.product.qtyIncrementor = r.product.qty_incrementor
            r.product.minQty = r.product.min_qty

            r.product.mrp = parseFloat(r.product.mrp)
            // delete r.map
            delete r.product.qty_label
            delete r.product.qty_incrementor
            delete r.product.min_qty
            
            delete r.product.desc
            delete r.product.img

            setProduct(r.product)
            setType('edit')
        }).catch(e => {
            console.log(e)
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to get product', type: 'error' }))
        })
    }
    const findDiscounts = (id) => {
        return new Promise((resolve, reject) => {
            Product.discounts(id).then((r) => {
                r.productPricePoint.map((d) => d.userType = d.user_type_id)
                resolve(r.productPricePoint)
            }).then((err) => {
                reject(err)
            })
        })
    }
    const findUserTypes = () => {
        return new Promise((resolve, reject) => {
            UserTypes.get().then((r) => {
                resolve(r.userTypes)
            }).catch(err => {
                reject(err)
                console.log(err)
            })
        })
    }
    const priceRemove = (id) => {
        let index = discounts.findIndex(d => d.id === id)
        discounts.splice(index, 1)
        setDiscounts([...discounts])
        Product.removeDiscounts(productId, id).then((response) => {
            console.log(response)
            dispatch(toaster.actions.openToaster({ open: true, message: 'Price successfully removed', type: 'success' }))
        }).catch(err => {
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to remove price', type: 'error' }))
        })

    }
    const savePrice = (values) => {
        console.log(values)
        const action = values.id ? 'edit' : 'add'
        const data = {
            user_type_id: values.userType,
            price: values.price
        }
        if (action === 'edit') data['_method'] = 'PUT'
        Product.saveDiscounts(productId, data, values.id).then((r) => {
            let __discounts = [...discounts]
            if (action === 'add') {
                r.productPricePoint.userType = r.productPricePoint.user_type_id
                __discounts.push(r.productPricePoint)
                setDiscounts(__discounts)
            }
            else {
                const __discount = __discounts.find((e) => e.id === values.id)
                if (__discount) {
                    r.productPricePoint.userType = r.productPricePoint.user_type_id
                    Object.assign(__discount, r.productPricePoint)
                    setDiscounts(__discounts)
                }
            }
            dispatch(toaster.actions.openToaster({ open: true, message: 'Price successfully saved', type: 'success' }))
        }).catch(e => {
            console.log(e)
            dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to save price', type: 'error' }))
        })
    }
    const userTypeChanged = (action, id, removeId) => {
        let index = usedUserTypes.indexOf(id)
        if (action === 'add' && index === -1) {
            usedUserTypes.push(id)
            if (removeId) {
                let rmItemIndex = usedUserTypes.indexOf(removeId)
                if (rmItemIndex !== -1) usedUserTypes.splice(rmItemIndex, 1)
            }
        }
        else if (action === 'remove' && index !== -1) usedUserTypes.splice(index, 1)
        dispatch(_usedUserTypes.actions.setStore(usedUserTypes))
    }
    const getTittle = () => {
        if (type === 'add') return 'Add Products'
        else {
            if (tabIndex === 0) return 'Edit Products'
            else return 'Update discounts price of ' + data.name
        }
    }
    return (
        <Card>
            {saving && (<ModalProgressBar />)}
            <CardHeader title={getTittle()}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={back}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left"></i>
                        Back
                    </button>
                    {`  `}
                    {tabIndex !== 1 && (
                        <>
                            <button
                                className="btn btn-light ml-2"
                                onClick={resetForm}>
                                <i className="fa fa-redo"></i>
                            Reset
                                </button>
                            {`  `}
                            <button
                                disabled={saving}
                                type="submit"
                                className="btn btn-primary ml-2"
                                onClick={saveProductClick}
                            >
                                {saving ? 'Saving...' : 'Save'}
                            </button>
                        </>
                    )}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody className="contains-tabs">
                <Paper square>
                    <Tabs value={tabIndex} indicatorColor="primary" textColor="primary" onChange={handleChange}>
                        <Tab label="Details" />
                        <Tab label="Discount" disabled={type === 'add'} />
                    </Tabs>
                </Paper>
                {tabIndex === 0 && (
                    <ProductEditForm
                        // actionsLoading={actionsLoading}
                        data={data}
                        btnRef={btnRef}
                        resetbtnRef={resetbtnRef}
                        saveProduct={saveProduct}
                        categories={categories}
                        type={type}

                    >
                    </ProductEditForm>
                )}
                {tabIndex === 1 && (
                    <ProductDiscountForm
                        product={data}
                        data={discounts}
                        btnRef={btnRef}
                        resetbtnRef={resetbtnRef}
                        saveDiscounts={saveDiscounts}
                        priceRemove={priceRemove}
                        userTypes={userTypes}
                        type={type}
                        price={data.mrp ? parseFloat(data.mrp) : 0}
                        savePrice={savePrice}
                        userTypeChanged={userTypeChanged}
                    ></ProductDiscountForm>
                )}
            </CardBody>
        </Card>
    )
};
