
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
import { Formik } from "formik";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

export function DispatchPopUP({
    open,
    handleClose,
    handleSuccess,
    drivers, 
    data
}) {
    const [driver, setDriver] = useState({})
    const [selectedDriver, setSelectedDriver] = useState(null)
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    const onError = function (event) {
        console.log(event.target)
        event.target.src = toAbsoluteUrl("/media/images/image-gallery.png")
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                className="assign-driver"
            >
                {/* <DialogTitle id="scroll-dialog-title"> */}
                <div
                    className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                    style={{ backgroundImage: `url(${bgImage})` }}
                >
                    <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                        <i className="flaticon2-shopping-cart-1 text-success"></i>
                    </span>
                    <h4 className="text-white m-0 flex-grow-1 mr-3">Select a driver</h4>
                </div>

                <div className="p-8 driver-select">
                    <>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{}}
                            onSubmit={(values) => {
                                // applyFilter(values);
                            }}
                        >
                            {({ handleSubmit, resetForm, setFieldValue, errors, values }) => (
                                <>

                                    <div className="dispatch-filter-row">
                                        <div className="coloums first">
                                            <AutoComplete
                                                value={driver}
                                                label="Location"
                                                name="location"
                                                options={drivers}
                                                onChange={(value) => setSelectedDriver(value)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </>

                </div>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={selectedDriver === null} onClick={() => handleSuccess(selectedDriver)} color="primary" className="btn btn-primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
