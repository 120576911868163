import React from 'react';
import { Card } from 'react-bootstrap';

export default function (props) {
 const { data } = props
    return (
        <>
        <div className="d-flex justify-content-center" style={{width:'100%'}} >
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                        <Card.Body>
                        <Card.Title>Total Orders</Card.Title>
                        <p style={{
                            fontSize: '20px'
                        }}>
                        {data.orders_count ? data.orders_count : 0}
                        </p>
                        </Card.Body>
                    </Card>
                </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                        <Card.Body>
                        <Card.Title>Total Amount</Card.Title>
                        <p style={{
                            fontSize: '20px'
                        }}>
                        {data.total_order_amount ? data.total_order_amount : 0}
                        </p>
                        </Card.Body>
                    </Card>
                </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                    <Card.Body>
                    <Card.Title>Total Rewards</Card.Title>
                        <p style={{
                            fontSize: '20px'
                        }}>
                        {data?.reward_points?.points ? data?.reward_points?.points : 0}
                        </p>
                    </Card.Body>
                </Card>
            </div>
        </div>
</>
    )
}