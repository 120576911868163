export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    })
}

export const datePipe = (d) => {
    d = new Date(d)
    let month = d.getMonth() + 1
    let date = d.getDate()
    let year = d.getFullYear()
    if (month.length === 1) month = '0' + month
    return `${date}/${month}/${year}`
}
export const dateTimePipe = (d) => {
    let o = new Intl.DateTimeFormat("en", {
        timeStyle: "short",
        dateStyle: "medium"
    });
    return o.format(new Date(d))
}

export const getFormatedDate = (d) => {
    d = new Date(d)
    let month = (d.getMonth() + 1).toString()
    let date = d.getDate().toString()
    let year = d.getFullYear()
    if (month.length === 1) month = '0' + month
    if (date.length === 1) date = '0' + date
    return `${year}-${month}-${date}`
}