import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CommonTable } from "../page-components/CommonTable";
import { UserTypes } from "../../utlis/API"
import { sortCaret } from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
import * as toaster from "../../../redux/reducer-extras/toasterRedux";
import * as alert from "../../../redux/reducer-extras/alertRedux";
const _ListUserTypes = (props) => {
  const dispatch = useDispatch()
  const paginationOptions = {
    custom: true,
    totalSize: 100,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: 5,
    page: 1,
  };
  const columns = [
    {
      dataField: "id",
      text: "SL NO",
      sort: true,
      sortCaret: sortCaret,
      formatExtraData: ({ rowIndex }) => {
        return (<span>{rowIndex + 1}</span>)
      }
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditProductPage: (id) => { props.history.push(`/user-types/${id}/edit`) },
        openDeleteProductDialog: (id) => { deleteProduct(id) },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const [init, setInit] = useState(true);
  const [userTypes, setUserTypes] = useState([]);
  const [tempUserTypes, setTempUserTypes] = useState([]);
  const [_paginationOptions, setPaginationOptions] = useState(paginationOptions);

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    onTableChanged('page', _paginationOptions)
  }, [tempUserTypes])
  useEffect(() => {
    setLoading(true)
    UserTypes.get().then((response) => {
      try {
        setTempUserTypes([...response.userTypes])
        let userTypes = response.userTypes.slice(0, paginationOptions.sizePerPage)
        paginationOptions.totalSize = response.userTypes.length
        setUserTypes(userTypes)
        setPaginationOptions(paginationOptions)
      }
      catch (e) {
        console.log(e)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // props.openToaster({ open: true, message: 'Failed to fetch category', type: 'error' })
    })

  }, [init])
  const onTableChanged = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    let start = (pageNumber - 1) * sizePerPage
    let userTypes = tempUserTypes.slice(start, start + sizePerPage)
    _paginationOptions.sizePerPage = sizePerPage
    _paginationOptions.page = pageNumber
    setUserTypes(userTypes)
    setPaginationOptions(_paginationOptions)
  }
  const deleteProduct = (id) => {
    dispatch(alert.actions.showAlert({
      open: true,
      message: 'Are you sure you want to delete this user type',
      type: 'confirm',
      title: 'Delete product',
      cb: (status) => {
        if (status) proceedDelete(id)
      }
    }))
  }
  const proceedDelete = (id) => {
    UserTypes.delete(id).then((res) => {
      let _tempUserTypes = [...tempUserTypes]
      let index = _tempUserTypes.findIndex((e) => e.id == id)
      if (index != -1) _tempUserTypes.splice(index, 1)
      setTempUserTypes([..._tempUserTypes])
      dispatch(toaster.actions.openToaster({ open: true, message: 'User type successfully deleted', type: 'success' }))
    }).catch(e => {
      dispatch(toaster.actions.openToaster({ open: true, message: 'Failed to delete user type', type: 'error' }))
    })
  }
  return (
    <Card>
      <CardHeader title="User types">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/user-types/add')}
          >
            Add new user type
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CommonTable
          entities={userTypes}
          onChange={onTableChanged}
          paginationOptions={_paginationOptions}
          columns={columns}
          listLoading={loading}
        />
      </CardBody>
    </Card>
  )
};
export const ListUserTypes = injectIntl(connect(null, toaster.actions)(_ListUserTypes))

