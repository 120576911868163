import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import PerfectScrollbar from "react-perfect-scrollbar";
import { dateTimePipe } from "../../utlis/CommonMethods"
import { CommonTable } from "../page-components/CommonTable";
import { sortCaret } from "../../../_metronic/_helpers"
import BootstrapTable from "react-bootstrap-table-next";
import Stepper from "react-stepper-horizontal";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};
const paginationOptions = {
    custom: false,
    totalSize: 100,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: 5,
    page: 1,
  };
  const step1Content = <h1>Step 1 Content</h1>;
const step2Content = <h1>Step 2 Content</h1>;
const step3Content = <h1>Step 3 Content</h1>;
  const imageView = (cellContent, row, rowIndex,) => {
    console.log("cellContent",cellContent)
    return (
      <img src={cellContent} className="table-image" alt={cellContent.img}/>
    )
  }
const columns = [
    {
        dataField: "product.img",
        text: "",
        formatter: imageView
    },
    {
        dataField: "product.name",
        text: "Items",
    },
    {
        dataField: "qty",
        text: "Qty",
    },
    // {
    //     dataField: "mrp_per_unit",
    //     text: "Price",
    // },
    {
        dataField: "mrp_per_unit",
        text: "Price",
        formatter: (cellContent, row, rowIndex) => {
            let sum = row.qty * row.mrp_per_unit;
            return (<span key={row.id}>{Number(sum).toFixed(2)}</span>)
        }
    },
]

export function OrderDetails({
    open,
    handleClose,
    data
}) {
    console.log("data",data)
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    const onError = function (event) {
        console.log(event.target)
        event.target.src = toAbsoluteUrl("/media/images/image-gallery.png")
    }
    const products = () => {
        return (
            <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={false}
            remote
            keyField="id"
            data={data.order_items}
            columns={columns}
        >
             </BootstrapTable>
        )
        // return data.order_items.map((item, index) => {
        //     return (
        //         <div key={data.id + 'order-items' + index}>
        //             <div className="d-flex align-items-center justify-content-between p-8" >
        //                 <div className="d-flex flex-column mr-2">
        //                     <a className="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
        //                         {(item.product && (item.product.name))}
        //                     </a>
        //                     <span className="text-muted">
        //                         {(item.product && (item.product.desc))}
        //                     </span>
        //                     <div className="d-flex align-items-center mt-2">
        //                         <span className="text-muted mr-1">Qty</span>
        //                         <span style={{ marginLeft: '5px' }} className="font-weight-bold mr-2 text-dark-75 font-size-3">
        //                             {item.qty}
        //                         </span>
        //                         <span style={{ marginLeft: '20px' }} className="font-weight-bold mr-1 text-dark-75 font-size-3">
        //                             <span className="rs">RS</span> {item.mrp_per_unit}
        //                         </span>

        //                     </div>
        //                 </div>
        //                 <a href="#" className="symbol symbol-70 flex-shrink-0">
        //                     <img
        //                         onError={onError}
        //                         src={item.product.img}
        //                         alt=""
        //                     />
        //                 </a>
        //             </div>
        //             {(data.order_items.length != index + 1) && (<div className="separator separator-solid"></div>)}
        //         </div>
        //     )
        // })
    }
    const orderStatus = () => {
        return data.order_trackings.map((item, index) => {

            return (
                <a href="#" className="navi-item" key={data.id+'-order-tracking-'+index}>
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-file text-success"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                {item.notes}
                              </div>
                            <div className="text-muted">{ dateTimePipe(item.created_at)}</div>
                        </div>
                    </div>
                </a>
            )
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
            >
                {/* <DialogTitle id="scroll-dialog-title"> */}
                <div
                    className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                    style={{ backgroundImage: `url(${bgImage})` }}
                >
                    <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                        <i className="flaticon2-shopping-cart-1 text-success"></i>
                    </span>
                    <h4 className="text-white m-0 flex-grow-1 mr-3">Order Details of order-{data.id}</h4>
                    <button type="button" className="btn btn-success btn-sm">
                        {data.order_items.length} Items
                    </button>
                </div>
                {/* </DialogTitle> */}
                <div className="p-8">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Total MRP
                        </span>
                        <span className="font-weight-bolder text-dark-50 text-right">
                            <span className="rs">RS</span> {data.total_mrp}
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Paid amount
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                            <span className="rs">RS</span> {data.amount_to_be_paid}
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between  mb-2">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Transaction type
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                            <b>{data.transaction.payment_method}</b>
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between  mb-2">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Customer Name
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                            <b>{data.name}</b>
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between  mb-2">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Mobile
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                            <b>{data.mobile}</b>
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Address
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                            <b>{data.address_line_1}</b>
                            <b>{data.address_line_2}</b>
                            <b>{data.country}</b>
                            <b>{data.city}</b>
                            <b>{data.state}</b>
                            <b>{data.post_code}</b>
                        </span>
                    </div>
                </div>
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll"
                    style={{ maxHeight: "35vh", minHeight: "130px", position: "relative" }}
                >
                    <div className="progress-font">
      <Stepper 
      titleFontSize = {8}
      steps={ [{title: 'Order Placed'}, 
      {title: 'Confirmed'}, 
      {title: 'Assigned'}, 
      {title: 'On The Way'}, 
      {title: 'Delivered'}] } activeStep={ data.order_items.order_status_id } />
    </div>
                    {products()}
                    <div className="navi navi-hover scroll my-4">
                        {orderStatus()}
                    </div>
                </PerfectScrollbar>
                
               

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
