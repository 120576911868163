// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { AutoComplete } from "../../../_metronic/_partials/controls/forms/AutoComplete";
import { useSelector } from "react-redux";
// Validation schema
const ProductDiscountSchema = Yup.object().shape({
    userType: Yup.string('User type is required'),
    price: Yup.number()
        .required("MRP is required")
        .min(0, 'Minmum value is 0')

})

const PriceItems = ({
    data,
    save,
    clear,
    add,
    price,
    userTypes,
    type,
    index,
    showAdd,
    cstId,
    savePrice,
    userTypeChanged
}) => {
    const usedUserTypes = useSelector(
        ({ usedUserTypes }) => usedUserTypes.used
    );
    const [validationSchema, setValidationSchema] = useState(ProductDiscountSchema)
    const [selectedUserType, setSelectedUserType] = useState({})

    const [availableTypes, setAvailableTypes] = useState([])
    // const 
    useEffect(() => {
        const ProductDiscountSchema = Yup.object().shape({
            userType: Yup.string('User type is required'),
            price: Yup.number()
                .required("MRP is required")
                .min(1, 'Minmum value is 1')
                .max(price - 1, 'Rate must be below than the product price ' + price)
        })
        setValidationSchema(ProductDiscountSchema)
        if (data.userType !== "") {
            let _uType = userTypes.findIndex(e => e.id === data.userType)
            if (_uType !== -1) {
                setSelectedUserType({
                    label: userTypes[_uType].name,
                    value: userTypes[_uType].id
                })
            }
        }
    }, [data])
    useEffect(() => {
        let types = getUserTypes(ref.current.values)
        setAvailableTypes(types)
    }, [usedUserTypes])

    const getUserTypes = (values) => {
        let _userTyps = []
        userTypes.forEach((type) => {
            if (type.id === values.userType) _userTyps.push({ label: type.name, value: type.id })
            if (usedUserTypes.indexOf(type.id) === -1) _userTyps.push({ label: type.name, value: type.id })
        })
        return [..._userTyps]
    }
    const saveCondition = (oldValues, values, errors) => {
        let status = !(errors['userType'] || errors['price'])
        if (values.userType === "") return false
        if (values.price === "") return false
        if ((values.userType !== oldValues.userType) && status) return true
        if ((values.price !== oldValues.price) && status) return true
        return false
    }
    const ref = useRef(null);
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    save(values);
                }}
                innerRef={ref}
            >
                {({ handleSubmit, resetForm, setFieldValue, errors, touched, values, ...others }) => (
                    <>
                        <Form className="form form-label-right col-12">
                            <div className="row mb-5">
                                <div className="col-4">
                                    <AutoComplete
                                        value={selectedUserType}
                                        name="userType"
                                        options={availableTypes}
                                        onChange={(value) => {
                                            let old = { ...values }
                                            if (old.userType !== value) {
                                                userTypeChanged('add', value, old.userType !== "" ? old.userType : undefined)
                                            }
                                            setFieldValue("userType", value)
                                        }} />

                                </div>
                                <div className="col-4">
                                    <Field
                                        name="price"
                                        component={Input}
                                        type="number"
                                    />
                                </div>
                                <div className="col-4">
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-light ml-2"
                                            onClick={() => {
                                                userTypeChanged('remove', values.userType)
                                                clear(cstId, values.id)
                                            }}
                                        >-</button>
                                        {showAdd && (
                                            <button
                                                type="button"
                                                className="btn btn-primary ml-2"
                                                onClick={() => add()}
                                            >+</button>
                                        )}
                                        {saveCondition(data, values, errors, touched) && (
                                            <button
                                                type="button"
                                                className="btn btn-success ml-2"
                                                onClick={() => savePrice(values)}
                                            >Save</button>
                                        )}
                                    </>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    )
}
const initialDiscountData = {
    userType: '',
    price: '',
    cstId: Date.now()
}
export function ProductDiscountForm({
    data,
    price,
    userTypes,
    priceRemove,
    savePrice,
    usedUserTypes,
    userTypeChanged
}) {
    const [elements, setElments] = useState([initialDiscountData])
    const save = () => { }
    const clear = (cstId, priceId) => {
        // TODO confirmations
        let elementIndex = elements.findIndex(e => e.cstId === cstId)
        elements.splice(elementIndex, 1)
        if (priceId) priceRemove(priceId)
        if (elementIndex === 0 && elements.length === 0) elements.push(getElement())
        setElments([...elements])
    }
    const add = () => {
        elements.push(getElement())
        setElments([...elements])
    }
    useEffect(() => {
        const ele = []
        const length = data.length
        for (let i = 0; i < length; i++) ele.push(getElement())
        if (length === 0) ele.push(getElement())
        setElments([...ele])
    }, [data])
    // const userTypeChanged
    const getElement = () => {
        let newElem = { ...initialDiscountData }
        newElem.cstId = Math.floor(Date.now() * Math.random())
        return newElem
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-4 font-weight-600">
                            <label>User type</label>
                        </div>
                        <div className="col-4 font-weight-600">
                            <label>Price</label>
                        </div>
                        {/* {Editable} */}
                        {
                            elements.map((item, index) => (
                                <PriceItems
                                    data={data[index] ? data[index] : item}
                                    savePrice={savePrice}
                                    clear={clear}
                                    add={add}
                                    price={price}
                                    userTypes={userTypes}
                                    key={item.cstId}
                                    index={index}
                                    cstId={item.cstId}
                                    type='new'
                                    showAdd={elements.length === index + 1}
                                    usedUserTypes={usedUserTypes}
                                    userTypeChanged={userTypeChanged}
                                />)
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
