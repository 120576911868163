import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { ListCats, AddCats } from "./index"


export const Categories = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Categories");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/categories"
        to="/categories/list"
      />
      <ContentRoute from="/categories/add" component={AddCats} />
      <ContentRoute from="/categories/list" component={ListCats} />
      <ContentRoute from="/categories/:id/edit" component={AddCats} />
    </Switch>
  );
};
