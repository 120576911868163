import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import { AddTimeSlot } from "./AddTimeSlots"
import { ListTimeSlots } from "./ListTimeSlots"


export const TimeSlots = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Time slots");
    

    return (
        <Switch>
            <Redirect
                exact={true}
                from="/time-slots"
                to="/time-slots/list"
            />
            <ContentRoute from="/time-slots/add" component={AddTimeSlot} />
            <ContentRoute from="/time-slots/list" component={ListTimeSlots} />
            <ContentRoute from="/time-slots/:id/edit" component={AddTimeSlot} />
        </Switch>
    );
};
