import React, { useEffect, useState } from "react";
import {
    MixedWidget1,
    // MixedWidget14,
    ListsWidget9,
    StatsWidget11,
    StatsWidget12,
    ListsWidget1,
    // AdvanceTablesWidget2,
    // AdvanceTablesWidget4,
    // ListsWidget3,
    // ListsWidget4,
    // ListsWidget8
} from "../widgets";

import { GetopStores, GetActivities } from '../../../app/utlis/API'

export function Demo1Dashboard(props) {
    const [topStores, setTopStores] = useState([])
    const [salesActivity, setSalesActivity] = useState([])
    useEffect(() => {
        findTopStores()
        findActivities()
    }, [props.location])

    useEffect(() => {
        let interval = setInterval(() => {
            clearInterval(interval)
            findActivities()
        }, 10000)
        return function cleanup() {
            clearInterval(interval)
        }
    }, [salesActivity])

    const findTopStores = () => {
        GetopStores().then(response => {
            setTopStores([...response.top_stores])
        }).catch(err => {
            console.log(err)
        })
    }
    const findActivities = () => {
        GetActivities().then(response => {
            // console.log(response)
            setSalesActivity([...response.orders])
        }).catch(err => {
            console.log(err)
        })
    }
    return (<>
        <div className="row">
            <div className="col-lg-6 col-xxl-4">
                <MixedWidget1 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4">
                <ListsWidget9 className="card-stretch gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-4">
                <StatsWidget11 className="card-stretch card-stretch-half gutter-b" />
                <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                <ListsWidget1 stores={topStores} className="card-stretch gutter-b" />
            </div>
            {/* <div className="col-xxl-8 order-2 order-xxl-1">
                    <AdvanceTablesWidget2 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget3 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget8 className="card-stretch gutter-b"/>
                </div> */}
        </div>
        {/* <div className="row">
                <div className="col-lg-4">
                    <MixedWidget14 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-8">
                    <AdvanceTablesWidget4 className="card-stretch gutter-b" />
                </div>
            </div> */}
    </>);
}
