import React, { useRef, useState, useEffect } from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import Select from 'react-select'

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control", "form-control-solid"];
    if (touched && errors) {
        classes.push("is-invalid-select");
    }

    if (touched && !errors) {
        classes.push("is-valid-select");
    }

    return classes.join(" ");
};

export function AutoComplete({
    label,
    withFeedbackLabel = true,
    type = "text",
    customFeedbackLabel,
    children,
    options,
    value={},
    onChange,
    ...props
}) {
    const [field, meta] = useField(props);
    const { touched, error } = meta;
    const selectRef = useRef();
    const [val, setVal] = useState(value)
    const optionChanged = (ev)=>{
        selectRef.current.value = ev.value
        onChange(ev.value)
        if (ev.label) setVal(ev)
    }
    useEffect(()=>{ 
        setVal(value)
    }, [value])
    return (
        <>
            {label && <label>Select {label}</label>}
            {val.label && <Select
                value={val}
                options={options}
                onChange={optionChanged}></Select> }
            {!val.label &&
                <Select 
                    className={'autocomplete ' + (error && touched ? 'is-invalid' : null)}
                    options={options} 
                    onChange={optionChanged}></Select>}
            <select
                ref={selectRef}
                className={getFieldCSSClasses(touched, error)}
                style={{ display: "none" }}
                field={{...field}}
            >
                {children}
            </select>
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={error}
                    touched={touched}
                    label={label}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
