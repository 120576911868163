import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar
} from "../../../_metronic/_partials/controls";
import { connect } from "react-redux";
import * as toaster from "../../../redux/reducer-extras/toasterRedux";

import { BannerEditForm } from "./TimeSlotsEditForm";
import { TimeSlots } from "../../utlis/API"
import { injectIntl } from "react-intl";

export const _AddTimeSlot = (props) => {
    const [data, setData] = useState({ name: '', from: '', to: '' });
    const [init,] = useState(true);
    const [saving, setSaving] = useState(false);
    const [type, setType] = useState('add')
    const [slotId, setId] = useState(null)
    const back = () => {
        props.history.push('/time-slots/list')
    }

    const save = (values) => {
        setSaving(true)
        let formdata = new FormData()
        formdata.append('name', values.name)
        formdata.append('from', values.from)
        formdata.append('to', values.to)
        console.log(values)
        if (type === 'edit') formdata.append('_method', 'PUT')
        TimeSlots.save(formdata, slotId).then((res) => {
            props.openToaster({ open: true, message: 'Time slot successfully saved', type: 'success' })
            if (type === 'add') resetbtnRef.current.click()
            else back()
            setSaving(false)
        }).catch(err => {
            setSaving(false)
            props.openToaster({ open: true, message: 'Failed to save time slot', type: 'error' })
            console.log(err)
        })
    }
    const btnRef = useRef();
    const resetbtnRef = useRef()
    const saveClick = () => {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };
    const resetForm = () => {
        if (resetbtnRef && resetbtnRef.current) {
            resetbtnRef.current.click()
        }
    }
    useEffect(() => {
        try {
            let id = props.match.params.id
            if (id !== undefined) fetchData(id)
        }
        catch (e) {
            console.log(e)
        }
    }, [init])
    const fetchData = (id) => {
        TimeSlots.getById(id).then((r) => {
        // TimeSlots.get().then((r) => {
            setId(id)
            r.time_slot = formatTime(r.time_slot)
            setData(r.time_slot)
            // console.log(r.time_slots)
            setType('edit')
        }).catch(e => {
            console.log(e)
            props.openToaster({ open: true, message: 'Failed to get time slot', type: 'error' })
        })
    }
    const formatTime = (data) => {
        let fromTime = data.from.split(":")
        let fromHour = parseInt(fromTime[0])
        let fromMintue = parseInt(fromTime[1])
        let fromDate = fixDates()
        fromDate.setHours(fromHour)
        fromDate.setMinutes(fromMintue)

        let toTime = data.to.split(":")
        let toHour = parseInt(toTime[0])
        let toMintue = parseInt(toTime[1])
        let toDate = fixDates()
        toDate.setHours(toHour)
        toDate.setMinutes(toMintue)

        data.to = toDate
        data.from = fromDate
        return data

    }
    const fixDates = () => {
        const date = new Date()
        date.setDate(0)
        date.setMonth(0)
        date.setFullYear(0)
        date.setSeconds(0)
        return new Date(date)
    }
    return (
        <Card>
            {saving && (<ModalProgressBar />)}
            <CardHeader title={type !== 'edit' ? 'Add time slot' : 'Edit time slot'}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={back}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left"></i>
              Back
            </button>
                    {`  `}
                    <button
                        className="btn btn-light ml-2"
                        onClick={resetForm}>
                        <i className="fa fa-redo"></i>
              Reset
            </button>
                    {`  `}
                    <button
                        disabled={saving}
                        type="submit"
                        className="btn btn-primary ml-2"
                        onClick={saveClick}
                    >
                        {saving ? 'Saving...' : 'Save'}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="mt-5">
                    <BannerEditForm
                        data={data}
                        btnRef={btnRef}
                        resetbtnRef={resetbtnRef}
                        save={save}
                        type={type}
                    >
                    </BannerEditForm>
                </div>
            </CardBody>
        </Card>
    )
};


export const AddTimeSlot = injectIntl(connect(null, toaster.actions)(_AddTimeSlot))